/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import DateUtils from 'helpers/DateUtils'
import { getCaseStatusColor } from 'helpers/caseUtils'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  Card,
} from '@mui/material'
import LoadingOverlay from 'components/Loader/LoadingOverlay'
import Table from 'components/Table/Table'
import ColoredSquareChip from 'components/ColoredSquareChip/ColoredSquareChip'
import CaseInvoiceMobileTableCard from './CaseInvoiceMobileTableCard'

/* Type imports ------------------------------------------------------------- */
import type {
  CodeLabel,
  PieceJointe,
} from 'API/__generated__/Api'
import type {
  ColumnHeader,
  Order,
} from 'types/Table'

/* Styled components -------------------------------------------------------- */
const TableCardContainer = styled(Card)`
  margin-top: 20px;

  @media ${(props) => props.theme.media.mobile.main} {
    display: none;
  }
`

const TableCardContentContainer = styled.div`
  padding: 0px 15px 5px;

  .status-chip {
    width: 100%;
  }
`

const MobileCardContainer = styled.div`
  display: none;

  @media ${(props) => props.theme.media.mobile.main} {
    display: block;
    min-height: 200px;
  }
`

const StatusChipContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  .status-chip {
    font-size: .8rem;
  }
`

/* Component declaration ---------------------------------------------------- */
interface CaseInvoiceTableProps {
  filteredList: PieceJointe[];
  isFetchingList: boolean;
  setFilteredList: (value: PieceJointe[]) => void;
  setOrder: (newOrder: Order) => void;
  setOrderBy: (newOrderBy: string) => void;
}

const CaseInvoiceTable: React.FC<CaseInvoiceTableProps> = ({
  filteredList,
  isFetchingList,
  setFilteredList,
  setOrder,
  setOrderBy,
}) => {
  const cols: ColumnHeader[] = [
    {
      id: 'id',
      label: 'N° Facture',
    },
    {
      id: 'date',
      label: 'Date de création',
      render: (date: string) => DateUtils.APIStrToLocalDateString(date),
    },
    {
      id: 'ht',
      label: 'HT',
    },
    {
      id: 'tva',
      label: 'TVA',
    },
    {
      id: 'ttc',
      label: 'TTC',
    },
    {
      id: 'statut',
      label: 'Statut',
      render: (status: CodeLabel, row) => (
        <StatusChipContainer>
          <ColoredSquareChip
            color={getCaseStatusColor(status.code)}
            className="status-chip"
          >
            {status.libelle}
            <br />
            {DateUtils.APIStrToLocalDateString(row?.date as string)}
          </ColoredSquareChip>
        </StatusChipContainer>
      ),
      renderForExport: (status: CodeLabel) => status.libelle || '',
    },
    {
      id: 'id',
      label: 'Actions',
      removeFromExport: true,
      render: (value: string) => (
        <div>
          <Button variant="outlined">
            Valider
          </Button>
        </div>
      ),
    },
  ]

  return (
    <LoadingOverlay isLoading={isFetchingList}>
      <TableCardContainer>
        <TableCardContentContainer>
          <Table
            rows={filteredList}
            setRows={(rows) => setFilteredList(rows as PieceJointe[])}
            colHeaders={cols}
            limit={25}
            defaultOrder={{ order: 'desc', orderBy: 'dateCreation' }}
            sorting={{ setOrder, setOrderBy }}
          />
        </TableCardContentContainer>
      </TableCardContainer>
      <MobileCardContainer>
        {
          filteredList.map((file, index) => (
            <CaseInvoiceMobileTableCard
              key={`${file.id}-${index}`}
              file={file}
            />
          ))
        }
      </MobileCardContainer>
    </LoadingOverlay>
  )
}

export default CaseInvoiceTable
