/* Framework imports -------------------------------------------------------- */
import React from 'react'

/* Component imports -------------------------------------------------------- */
import { InputAdornment } from '@mui/material'
import { Field } from 'formik'
import { TextField } from 'formik-mui'

/* Type imports ------------------------------------------------------------- */
import type { FieldAttributes } from 'formik'

/* Component declaration ---------------------------------------------------- */
interface PriceFieldProps {
  placeholder?: string;
  name: string;
  size?: string;
}

const PriceField: React.FC<FieldAttributes<PriceFieldProps>> = ({
  name,
  placeholder = '0,00',
  ...props
}) => {
  return (
    <Field
      component={TextField}
      name={name}
      type="number"
      placeholder={placeholder}
      InputProps={
        {
          endAdornment: (
            <InputAdornment position="end">
              €
            </InputAdornment>
          ),
          inputProps: {
            style: { textAlign: 'right' },
            min: 0,
            step: 0.01,
          },
          onWheel: (e: React.ChangeEvent<HTMLInputElement>) => e.target.blur(),
        }
      }
      {...props}
    />
  )
}

export default PriceField
