/* Framework imports -------------------------------------------------------- */
import React, { useState } from 'react'

/* Component imports -------------------------------------------------------- */
import {
  TextField,
  Autocomplete,
} from '@mui/material'

/* Module imports ----------------------------------------------------------- */
import { useGetEurogexQuery } from 'store/api'
import { fetchAutocompleteAddress } from 'helpers/getAddress'

/* Type imports ------------------------------------------------------------- */
import type { AutocompleteRenderInputParams } from '@mui/material'
import type { Feature } from 'geojson'

/* Component declaration ---------------------------------------------------- */
interface AddressAutocompleteProps {
  value: string;
  onValueChange: (newValue: string) => void;
  onAddressChange: (newAddress: Feature) => void;
  placeholder?: string;
  size?: 'small' | 'medium';
  autoSelect?: boolean;
  error?: boolean;
  helperText?: string;
}

const AddressAutocomplete: React.FC<AddressAutocompleteProps> = ({
  value,
  onValueChange,
  onAddressChange,
  placeholder = 'Sélectionnez',
  autoSelect = false,
  error = false,
  helperText,
  ...props
}) => {
  const [ addressList, setAddressList ] = useState<Feature[] | null>(null)

  const { currentData: eurogexCodes } = useGetEurogexQuery({ section: 'ANNUAIRE', rubrique: 'Périmetre API Adresse Gouv' })

  const fetchAddress = async (value: string) => {
    setAddressList(await fetchAutocompleteAddress(value, eurogexCodes?.valeur?.split(';')))
  }

  return (
    <Autocomplete
      options={addressList?.map((address) => address.properties?.label as string) ?? []}
      autoSelect={autoSelect}
      autoHighlight
      includeInputInList
      filterSelectedOptions
      freeSolo
      filterOptions={(x) => x}
      value={value}
      onChange={
        (event, newValue: string | null) => {
          const found = addressList?.find((address) => (address.properties?.label as string) === newValue)
          found && onAddressChange(found)
        }
      }
      onInputChange={
        (event, newInputValue) => {
          fetchAddress(newInputValue)
          onValueChange(newInputValue)
        }
      }
      renderInput={
        (params: AutocompleteRenderInputParams): React.ReactElement => (
          <TextField
            {...params}
            error={error}
            helperText={helperText}
            placeholder={placeholder}
            variant="outlined"
          />
        )
      }
      {...props}
    />
  )
}

export default AddressAutocomplete
