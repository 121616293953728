/* Framework imports -------------------------------------------------------- */
import React from 'react'

/* Component declaration ---------------------------------------------------- */
interface MapPinProps {
  color: string;
}

const MapPin: React.FC<MapPinProps> = ({ color }) => {

  return (
    <svg
      version="1.1"
      viewBox="0 0 94 128"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m46.977 126.64c-0.28303-0.6875-6.1631-6.4377-10.374-11.663-24.947-33.122-52.761-63.898-20.283-101.3 14.381-13.894 32.113-14.686 50.343-8.2101 51.33 29.702 14.166 78.155-10.235 110.01l-9.4501 11.163zm15.44-50.771c34.237-24.485 7.768-71.633-29.848-55.959-10.984 5.8569-16.434 16.048-16.625 28.068-0.5245 11.693 5.6854 21.471 15.526 27.461 7.0555 3.8401 10.157 4.5337 18.146 4.0577 5.1776-0.30852 8.1611-1.1538 12.801-3.6271z"
        fill={color}
      />
      <path
        transform="matrix(.87829 0 0 1.0519 .55474 -6.9953)"
        d="m41.682 89.891a51.223 41.754 0 1 1 1.2766 0.20809"
        fill={color}
      />
      <path
        transform="translate(.64535)"
        d="m43.631 69.129a26.011 20.991 0 1 1 0.64826 0.10461"
        fillOpacity="0"
        opacity=".34016"
      />
      <path
        transform="translate(.64535)"
        d="m31.892 114.28a16.656 11.75 0 1 1 0.41511 0.0586"
        fillOpacity="0"
      />
      <path
        transform="matrix(.9702 0 0 1.0272 -4.0588 -5.7504)"
        d="m45.521 84.824a34.453 33.54 0 1 1 0.85866 0.16716"
        fill={color}
      />
      <path
        transform="matrix(.6463 0 0 .61681 5.1261 4.9014)"
        d="m57.079 104.61a34.203 36.623 0 1 1 0.85244 0.18252"
        fill="white"
      />
    </svg>
  )
}

export default MapPin
