/* Framework imports -------------------------------------------------------- */
import React from 'react'

/* Component declaration ---------------------------------------------------- */
interface AttachmentButtonProps {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  children: React.ReactNode;
  multiple?: boolean;
  accept?: string;
  fullWidth?: boolean;
  name: string;
}

const AttachmentButton: React.FC<AttachmentButtonProps> = ({
  onChange,
  children,
  disabled = false,
  multiple = true,
  accept = '.pdf',
  fullWidth = false,
  name='attachment-button',
}) => {

  return (
    <label
      htmlFor={name}
      style={{ width: fullWidth ? '100%' : 'auto' }}
    >
      <input
        accept={accept}
        id={name}
        multiple={multiple}
        type="file"
        hidden
        onChange={onChange}
        disabled={disabled}
      />
      {children}
    </label>
  )
}

export default AttachmentButton
