/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import DateUtils from 'helpers/DateUtils'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'
import { Field } from 'formik'
import { TextField } from 'formik-mui'
import CloseButton from 'components/CloseButton/CloseButton'
import FormikDatePicker from 'components/DateTimePickers/FormikDatePicker'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import SegmentedButtons from 'components/SegmentedButtons/SegmentedButtons'
import CheckboxButton from 'components/CheckboxButton/CheckboxButton'
import AddressAutocomplete from 'components/FieldWithInputAdornment/AddressAutocomplete'
import CheckableButton from 'components/CheckableButton/CheckableButton'

/* Type imports ------------------------------------------------------------- */
import type { DateSelectArg } from '@fullcalendar/core'
import type { FormikContextType } from 'formik'
import type { Feature } from 'geojson'
import type { Shape } from 'components/FormikLogic/FormikLogic'
import type {
  IntervenantPlanning,
  Planning,
  TypeRdv,
  Telephones,
} from 'types/Planning'
import {
  TypeRdvType,
  currentStakeholders,
} from 'types/Planning'
import type { EventHandlerActionType } from 'types/EventHandler'
import type { SegmentedButtonOption } from 'components/SegmentedButtons/SegmentedButtons'
import type { AdresseRequest } from 'API/__generated__/Api'

/* Type declarations -------------------------------------------------------- */
interface EventRequest extends Planning {
}

const eventSchema = Yup.object<Shape<EventRequest>>({
  typeRDV: Yup.object<Shape<TypeRdv>>({
    id: Yup.string().required('Le type de RDV est obligatoire'),
  }).required(),
  dateDebut: Yup.string().nullable().required('Ce champ est obligatoire'),
  dateFin: Yup.string().nullable().required('Ce champ est obligatoire'),
  telephones: Yup.object<Shape<Telephones>>({
    mobile: Yup.string().test(
      'personnal-phone',
      'Le téléphone est invalide',
      (item = '') => !item || ((/^(06|07)/).test(item) && (item.replaceAll(' ', '').length === 10)),
    ),
  }),
}).required()

type EventForm = FormikContextType<EventRequest>

/* Styled components -------------------------------------------------------- */
interface GridContainerProps {
  size: number;
}

const GridContainer = styled.div<GridContainerProps>`
  display: grid;
  grid-template-columns: repeat(${(props) => props.size}, 1fr);
  gap: 10px;

  @media ${(props) => props.theme.media.mobile.main} {
    grid-template-columns: 1fr;
    gap: 0px;
  }

  .MuiInputBase-root.MuiOutlinedInput-root {
    height: 40px;
  }

  .stakeholder-checkbox {
    label {
      padding: 5px 5px 5px 0px;
      height: 40px;
    }
  }
`

const DialogTitleContainer = styled(DialogTitle)`
  font-weight: bold;
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 1.5rem;
  margin-top: 10px;
  padding-bottom: 0px;
  text-transform: uppercase;
  text-align: center;
`

const DialogActionContainer = styled(DialogActions)`
  justify-content: center;
  margin-bottom: 20px;

  button {
    width: 200px;
  }
`

const MiniCheckable = styled(CheckboxButton)`
  min-height: 40px;
  height: 40px;
`

const BlankDiv = styled.div`
  height: 10px;
`

const TypeLabel = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const Square = styled.div`
  background-color: ${(props) => props.color};
  width: 25px;
  height: 25px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: .9rem;
  color: ${(props) => props.theme.colors.dark};
`

const FullName = styled.div`
  max-width: 140px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

/* Component declaration ---------------------------------------------------- */
interface PlanningEventHandlerModalProps {
  open: boolean;
  handleClose: (type?: EventHandlerActionType | null, newEvent?: Planning) => void;
  action: EventHandlerActionType | null;
  event: Planning | undefined;
  dates: DateSelectArg | null;
  types: TypeRdv[];
  stakeholderId?: string;
}

const PlanningEventHandlerModal: React.FC<PlanningEventHandlerModalProps> = ({
  open,
  handleClose,
  action,
  event,
  dates,
  types,
  stakeholderId,
}) => {
  const getDateWithDayAndTime = (time: string, day: string): string => DateUtils.dateToAPIStr(new Date(new Date(new Date(time).setDate(new Date(day).getDate())).setMonth(new Date(day).getMonth())))

  const onSubmit = (values: EventRequest): void => {
    const newEvent: Planning = {
      id: values.dateDebut + values.dateFin,
      ...event,
      dateDebut: getDateWithDayAndTime(values.dateDebut, values.dateDebut),
      dateFin: getDateWithDayAndTime(values.dateFin, values.dateFin),
      commentaire: values.commentaire,
      adresse: values.adresse,
      typeRDV: { ...types.find((t) => t.id === values.typeRDV.id) as TypeRdv },
      journeeComplete: values.journeeComplete,
      telephones: values.telephones,
      intervenants: values.intervenants ?? [],
      dossierId: values.dossierId,
    }
    handleClose(action, newEvent)
  }

  const formikForm: EventForm = useForm<EventRequest>(
    {
      initialValues: {
        ...event,
        typeRDV: event?.typeRDV ?? { color: '', id: types[0].id, libelle: '', type: TypeRdvType.ActionGenerale },
        dateDebut: event?.dateDebut || dates?.start?.toISOString() || new Date().toISOString(),
        dateFin: event?.dateFin || dates?.end?.toISOString() || new Date(new Date().setHours(new Date().getHours() + 1)).toISOString(),
        journeeComplete: dates?.allDay ?? false,
        adresse: event?.adresse ?? { adresse1: '', adresse2: '', adresse3: '', codePostal: '', ville: '' },
        commentaire: event?.commentaire || '',
        id: event?.id || '',
        telephones: { fixe: event?.telephones.fixe || '', mobile: event?.telephones.mobile || '' },
        dossierId: event?.dossierId || '',
        intervenants: event?.intervenants || currentStakeholders.filter((stakeholder) => stakeholder.id === stakeholderId),
      },
      onSubmit: onSubmit,
      validationSchema: eventSchema,
    },
  )

  const onAddressChange = (newAddress: Feature) => {
    formikForm.setValues({
      ...formikForm.values,
      adresse: {
        ...formikForm.values.adresse,
        adresse1: newAddress.properties?.name as string,
        codePostal: newAddress.properties?.postcode as string,
        ville: newAddress.properties?.city as string,
      },
    })
  }

  const addressErrorProps = () => {
    const addressTouched = (formikForm.touched.adresse as unknown as AdresseRequest)?.adresse1
    const addressError = (formikForm.errors.adresse as unknown as AdresseRequest)?.adresse1
    return ({
      error: addressTouched && addressError !== undefined,
      helperText: addressTouched && addressError !== undefined ? addressError : undefined,
    })
  }

  const onStakeholderClick = (stakeholder: IntervenantPlanning) => () => {
    if (formikForm.values.intervenants?.includes(stakeholder)) {
      formikForm.setFieldValue('intervenants', formikForm.values.intervenants.filter(((s) => s.id !== stakeholder.id)))
    } else {
      formikForm.setFieldValue('intervenants', [ ...formikForm.values.intervenants ?? [], stakeholder ])
    }
  }

  const rdvTypes: SegmentedButtonOption<string>[] = types.map(({ id, libelle }) => ({ value: id, label: libelle }))

  return (
    <Dialog
      open={open}
      onClose={() => handleClose()}
      maxWidth="lg"
      fullWidth
    >
      <Form form={formikForm}>
        <DialogTitleContainer>
          {
            action === 'add' ?
              'Nouveau rendez-vous' :
              `Modifier ${event?.typeRDV?.libelle}`
          }
          <CloseButton handleClose={handleClose} />
        </DialogTitleContainer>
        <DialogContent>
          <FormBoldTitle required>
            Type de Rendez-vous
          </FormBoldTitle>
          <SegmentedButtons
            options={rdvTypes}
            setSelectedOption={(value) => formikForm.setFieldValue('typeRDV.id', value)}
            selectedOption={formikForm.values.typeRDV.id}
            smaller
          />
          <FormBoldTitle>
            Dossier
          </FormBoldTitle>
          <Field
            component={TextField}
            placeholder="Dossier"
            size="small"
            name="dossierId"
          />
          <GridContainer size={5}>
            <div>
              <FormBoldTitle required>
                Date de début
              </FormBoldTitle>
              <FormikDatePicker name="dateDebut" />
            </div>
            <div>
              <FormBoldTitle required>
                Heure de début
              </FormBoldTitle>
              <FormikDatePicker
                name="dateDebut"
                time
                disabled={formikForm.values.journeeComplete}
              />
            </div>
            <div>
              <FormBoldTitle required>
                Date de fin
              </FormBoldTitle>
              <FormikDatePicker name="dateFin" />
            </div>
            <div>
              <FormBoldTitle required>
                Heure de fin
              </FormBoldTitle>
              <FormikDatePicker
                name="dateFin"
                time
                disabled={formikForm.values.journeeComplete}
              />
            </div>
            <div>
              <FormBoldTitle>
                &zwnj;
              </FormBoldTitle>
              <MiniCheckable
                label="Journée entière"
                checked={formikForm.values.journeeComplete || false}
                onChange={(e, c) => formikForm.setFieldValue('journeeComplete', c)}
              />
            </div>
          </GridContainer>
          <FormBoldTitle>
            Adresse
          </FormBoldTitle>
          <AddressAutocomplete
            placeholder="Adresse"
            value={formikForm.values.adresse?.adresse1 || ''}
            onValueChange={(newValue: string) => formikForm.setFieldValue('adresse.adresse1', newValue)}
            onAddressChange={onAddressChange}
            size="small"
            {...addressErrorProps}
          />
          <BlankDiv />
          <GridContainer size={2}>
            <Field
              component={TextField}
              placeholder="Adresse"
              name="adresse.adresse2"
              size="small"
            />
            <Field
              component={TextField}
              placeholder="Adresse"
              name="adresse.adresse3"
              size="small"
            />
          </GridContainer>
          <GridContainer size={2}>
            <div>
              <FormBoldTitle>
                Code postal
              </FormBoldTitle>
              <Field
                component={TextField}
                placeholder="Code postal"
                name="adresse.codePostal"
                size="small"
              />
            </div>
            <div>
              <FormBoldTitle>
                Ville
              </FormBoldTitle>
              <Field
                component={TextField}
                placeholder="Ville"
                name="adresse.ville"
                size="small"
              />
            </div>
          </GridContainer>
          <FormBoldTitle>
            Commentaire adresse
          </FormBoldTitle>
          <Field
            component={TextField}
            placeholder="Votre message"
            rows={2}
            multiline
            name="commentaireAdresse"
            size="small"
          />
          <FormBoldTitle>
            Intervenants
          </FormBoldTitle>
          <GridContainer size={5}>
            {
              currentStakeholders.map((stakeholder, index) => (
                <CheckableButton
                  className="stakeholder-checkbox"
                  key={`${stakeholder.id}-${index}`}
                  checked={formikForm.values.intervenants?.includes(stakeholder) ?? false}
                  label={
                    <TypeLabel>
                      <FullName>
                        {`${stakeholder.prenom || ''} ${stakeholder.nom}`}
                      </FullName>
                      <Square color={stakeholder.color}>
                        {`${stakeholder.prenom?.[0] || ''}${stakeholder.nom[0]}`}
                      </Square>
                    </TypeLabel>
                  }
                  onChange={onStakeholderClick(stakeholder)}
                />
              ))
            }
          </GridContainer>
          <FormBoldTitle>
            N° de téléphone
          </FormBoldTitle>
          <GridContainer size={3}>
            <Field
              component={TextField}
              placeholder="Mobile"
              size="small"
              name="telephones.mobile"
            />
          </GridContainer>
          <FormBoldTitle>
            Commentaire général
          </FormBoldTitle>
          <Field
            component={TextField}
            placeholder="Votre message"
            rows={2}
            multiline
            size="small"
            name="commentaire"
          />
        </DialogContent>
        <DialogActionContainer>
          <Button onClick={() => handleClose()}>
            Annuler
          </Button>
          <Button
            type="submit"
            variant="contained"
          >
            {
              action === 'add' ?
                'Ajouter' :
                'Modifier'
            }
          </Button>
        </DialogActionContainer>
      </Form>
    </Dialog>
  )
}

export default PlanningEventHandlerModal
