/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Component imports -------------------------------------------------------- */
import { Button } from '@mui/material'
import {
  ArrowBackIosRounded,
  ArrowForwardIosRounded,
} from '@mui/icons-material'
import { DatePicker } from '@mui/x-date-pickers'

/* Styled components -------------------------------------------------------- */
const PlanningCalendarButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 10px;

  @media ${(props) => props.theme.media.mobile.portrait} {
    flex-direction: column;
    gap: 0px;
  }
`

const PlanningCalendarTitleContainer = styled.div`
  color: ${(props) => props.theme.palette.secondary.main};
  font-weight: bold;
  text-transform: uppercase;
  min-width: 240px;
  width: fit-content;
  justify-content: center;
  display: flex;
  align-items: center;

  @media ${(props) => props.theme.media.tablet} {
    min-width: 180px;
    font-size: 1rem;
  }

  @media ${(props) => props.theme.media.mobile.main} {
    min-width: 0px;
    font-size: 1rem;
  }
`

const ArrowButton = styled(Button)`
  height: 40px;
  width: 40px;
  min-width: 40px;
`

const CalendarButtonsContainer = styled.div`
  display: flex;
  align-items: center;

  @media ${(props) => props.theme.media.mobile.main} {
    flex-direction: column;
  }
`

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
`

const DateContainer = styled.div`
  display: flex;
  gap: 10px;
`

const CalendarInput = styled(DatePicker)`
  width: 120px;

  @media ${(props) => props.theme.media.desktop} {
    width: 160px;
  }

  .MuiInputBase-root {
    height: 40px;
  }
`

/* Component declaration ---------------------------------------------------- */
interface PlanningCalendarButtonsProps {
  calendarViewTitle: string;
  onPreviousClicked: React.MouseEventHandler<HTMLButtonElement>;
  onTodayClicked: React.MouseEventHandler<HTMLButtonElement>;
  onNextClicked: React.MouseEventHandler<HTMLButtonElement>;
  dateToClick: Date | null;
  onDateClicked: (value: Date | null) => void;
}

const PlanningCalendarButtons: React.FC<PlanningCalendarButtonsProps> = ({
  calendarViewTitle,
  onPreviousClicked,
  onTodayClicked,
  onNextClicked,
  dateToClick,
  onDateClicked,
}) => {
  return (
    <PlanningCalendarButtonsContainer>
      <CalendarButtonsContainer>
        <ButtonsContainer>
          <ArrowButton
            onClick={onPreviousClicked}
            color="secondary"
            id="previous"
          >
            <ArrowBackIosRounded />
          </ArrowButton>
          <PlanningCalendarTitleContainer id="planning-calendar-period">
            {calendarViewTitle}
          </PlanningCalendarTitleContainer>
          <ArrowButton
            onClick={onNextClicked}
            color="secondary"
            id="next"
          >
            <ArrowForwardIosRounded />
          </ArrowButton>
        </ButtonsContainer>
      </CalendarButtonsContainer>
      <DateContainer>
        <CalendarInput
          value={dateToClick}
          onChange={onDateClicked}
        />
        <Button
          variant="outlined"
          onClick={onTodayClicked}
        >
          Aujourd'hui
        </Button>
      </DateContainer>
    </PlanningCalendarButtonsContainer>
  )
}

export default PlanningCalendarButtons
