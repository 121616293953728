/* Framework imports -------------------------------------------------------- */
import { createSlice } from '@reduxjs/toolkit'

/* Module imports ----------------------------------------------------------- */
import type { RootState } from 'store/store'

/* Type imports ------------------------------------------------------------- */
import type { PayloadAction } from '@reduxjs/toolkit'

/* Type declarations -------------------------------------------------------- */
export interface CaseListFilterState {
  period: string;
}

/* Redux slice -------------------------------------------------------------- */
const caseListFilterSlice = createSlice(
  {
    name: 'caseListFilter',
    initialState: {
      period: new Date('2000-01-01').toLocaleString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
    } as CaseListFilterState,
    reducers: {
      setCaseListPeriod: (state, { payload }: PayloadAction<string>) => {
        state.period = payload
      },
    },
  },
)

/* Export slice components -------------------------------------------------- */
export const { setCaseListPeriod } = caseListFilterSlice.actions

export default caseListFilterSlice.reducer

export const getCaseListPeriod = (state: RootState): string => {
  return state.caseListFilter.period
}
