/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import DateUtils from 'helpers/DateUtils'

/* Type imports ------------------------------------------------------------- */
import type { EventContentArg } from '@fullcalendar/core'
import type { Planning } from 'types/Planning'
import { CalendarViewTypes } from 'helpers/FullCalendarOptions'

/* Styled components -------------------------------------------------------- */
interface TimelineEventContainerProps {
  color: string;
}

const TimelineEventContainer = styled.div<TimelineEventContainerProps>`
  background-color: ${(props) => props.color};
  color: ${(props) => props.theme.palette.secondary.main};
  border-radius: 4px;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1px 3px;
  justify-content: space-between;
  min-height: 20px;
`

const DateContainer = styled.div`
  font-size: .7rem;
  overflow: hidden;
  text-overflow: ellipsis;
`

const TitleContainer = styled.div`
  font-weight: bold;
  font-size: .8rem;
  overflow: hidden;
  text-overflow: ellipsis;
`

const StakeholderContainer = styled.div`
  display: flex;
  justify-content: end;
  gap: 3px;
`

interface ColoredBarProps {
  color: string;
}

const StakeholderBar = styled.div<ColoredBarProps>`
  background-color: ${(props) => props.color};
  width: 100%;
  height: 20px;
  border: 1px solid #00000019;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
`

/* Component declaration ---------------------------------------------------- */
interface PlanningCalendarTimelineViewProps {
  eventInfo: EventContentArg;
  event?: Planning;
  viewType: CalendarViewTypes;
}

const PlanningCalendarTimelineView: React.FC<PlanningCalendarTimelineViewProps> = ({
  eventInfo,
  event,
  viewType,
}) => {

  return (
    <TimelineEventContainer
      id={event ? `${event.commentaire}#${event.typeRDV?.libelle}` : `${eventInfo.event.id}`}
      color={eventInfo.event.backgroundColor}
    >
      {
        viewType !== CalendarViewTypes.HorizontalBusy &&
          <div>
            <DateContainer>
              {event && (event.journeeComplete ? 'Journée entière' : `${DateUtils.APIStrToLocalTimeString(eventInfo.event.startStr)} - ${DateUtils.APIStrToLocalTimeString(eventInfo.event.endStr)}`)}
            </DateContainer>
            <TitleContainer>
              {eventInfo.event.title}
            </TitleContainer>
          </div>
      }
      {
        viewType === CalendarViewTypes.Week &&
          <StakeholderContainer>
            {
              event?.intervenants?.map((stakeholder, index) => (
                <StakeholderBar
                  key={`${stakeholder.id}-${index}`}
                  color={stakeholder.color}
                >
                  {`${stakeholder.prenom?.[0] ?? ''}${stakeholder.nom[0]}`}
                </StakeholderBar>
              ))
            }
          </StakeholderContainer>
      }
    </TimelineEventContainer>
  )
}

export default PlanningCalendarTimelineView
