/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Component imports -------------------------------------------------------- */
import { Card } from '@mui/material'
import CaseHistoryJalon from './CaseHistoryJalon'
import CaseHistoryArrow from './CaseHistoryArrow'

/* Type imports ------------------------------------------------------------- */
import type { WorkflowEntreprise } from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const CardContainer = styled(Card)`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  margin-bottom: 10px;
  width: 100%;
`

const ArrowConatiner = styled.div`
  display: grid;
  grid-template-columns: repeat(8, 1fr);
`

const Wrapper = styled.div`
  min-width: fit-content;
  width: 100%;
`

const Container = styled.div`
  overflow-x: auto;
  margin-bottom: 20px;
`

/* Component declaration ---------------------------------------------------- */
interface CaseHistoryWorkflowProps {
  workflow: WorkflowEntreprise;
}

const CaseHistoryWorkflow: React.FC<CaseHistoryWorkflowProps> = ({ workflow }) => {
  return (
    <Container>
      <Wrapper>
        <CardContainer>
          <CaseHistoryJalon
            label="Réception"
            state={workflow.jalonCreation.etat}
            date={workflow.jalonCreation.dateCreation}
          />
          <CaseHistoryJalon
            label="Prise de contact"
            state={workflow.jalonPriseDeContact.etat}
            date={workflow.jalonPriseDeContact.dateValidation}
          />
          <CaseHistoryJalon
            label="Rdv métreur"
            state={workflow.jalonRDVMetre.etat}
            date={workflow.jalonRDVMetre.dateRendezVous}
          />
          <CaseHistoryJalon
            label="Devis"
            state={workflow.jalonDevis.etat}
            date={workflow.jalonDevis.dateAffichee}
          />
          <CaseHistoryJalon
            label="Commande"
            state={workflow.jalonDevis.etat}
            date={workflow.jalonDevis.dateAffichee}
          />
          <CaseHistoryJalon
            label="Travaux"
            state={workflow.jalonRDVTravaux.etat}
            date={workflow.jalonDevis.dateAffichee}
          />
          <CaseHistoryJalon
            label="Facture"
            state={workflow.jalonFacture.etat}
            date={workflow.jalonFacture.dateAffichee}
          />
          <CaseHistoryJalon
            label="Clotûre"
            state={workflow.jalonDossierTermine.etat}
            date={workflow.jalonDossierTermine.actionDate}
            last
          />
        </CardContainer>
        <ArrowConatiner>
          <CaseHistoryArrow
            id="jalon-1"
            state={workflow.jalonCreation.etat}
          />
          <CaseHistoryArrow
            id="jalon-2"
            state={workflow.jalonPriseDeContact.etat}
          />
          <CaseHistoryArrow
            id="jalon-3"
            state={workflow.jalonRDVMetre.etat}
          />
          <CaseHistoryArrow
            id="jalon-4"
            state={workflow.jalonDevis.etat}
          />
          <CaseHistoryArrow
            id="jalon-5"
            state={workflow.jalonDevis.etat}
          />
          <CaseHistoryArrow
            id="jalon-6"
            state={workflow.jalonRDVTravaux.etat}
          />
          <CaseHistoryArrow
            id="jalon-7"
            state={workflow.jalonFacture.etat}
          />
          <CaseHistoryArrow
            id="jalon-8"
            state={workflow.jalonDossierTermine.etat}
          />
        </ArrowConatiner>
      </Wrapper>
    </Container>
  )
}

export default CaseHistoryWorkflow
