/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useRef,
  useState,
} from 'react'
import L from 'leaflet'

/* Module imports ----------------------------------------------------------- */
import { renderToString } from 'react-dom/server'
import { fetchAutocompleteAddress } from 'helpers/getAddress'

/* Component imports -------------------------------------------------------- */
import {
  Marker,
  Popup,
} from 'react-leaflet'
import MapPin from 'components/MapPin/MapPin'
import PlanningTooltip from 'pages/PlanningPages/PlanningComponents/PlanningTooltip'

/* Type imports ------------------------------------------------------------- */
import type {
  Map,
  Marker as LMarker,
} from 'leaflet'
import type { Point } from 'geojson'
import type { Planning } from 'types/Planning'

/* Component declaration ---------------------------------------------------- */
interface PlanningMapMarkerProps {
  event: Planning;
  mapRef: Map | null;
  selectedEvent: string;
  setSelectedEvent: (event: Planning) => void;
  eventNumber: number;
}

const PlanningMapMarker: React.FC<PlanningMapMarkerProps> = ({
  event,
  mapRef,
  selectedEvent,
  setSelectedEvent,
  eventNumber,
}) => {
  const markerRef = useRef<LMarker | null>(null)
  const [ coordinates, setCoordinates ] = useState({ lat: 0, lng: 0 })

  const CustomMarker = () => {
    return (
      <div className="pin-container">
        <b>
          {eventNumber}
        </b>
        <MapPin color={event.typeRDV.color} />
      </div>
    )
  }

  const CustomDivIcon = L.divIcon({
    html: renderToString(<CustomMarker />),
    iconSize: [ 40, 60 ],
    iconAnchor: [ 0, 0 ],
    popupAnchor: [ 0, -45 ],
  })

  const getPosition = async () => {
    const address = await fetchAutocompleteAddress(`${event.adresse?.adresse1}, ${event.adresse?.codePostal}, ${event.adresse?.ville}`, [], 1)

    if (!address?.[0]) return

    const coordinates = (address[0]?.geometry as Point).coordinates
    setCoordinates({ lng: coordinates[0], lat: coordinates[1] })
  }

  useEffect(() => {
    if (selectedEvent === event?.id && coordinates.lat !== 0) {
      mapRef?.flyTo(coordinates, 17)
      markerRef?.current?.openPopup()
    }
  }, [ selectedEvent, event, mapRef, coordinates ])

  useEffect(() => {
    getPosition()
  }, [ event, mapRef ])

  if (coordinates.lat === 0) return null

  return (
    <Marker
      position={coordinates}
      ref={markerRef}
      icon={CustomDivIcon}
      eventHandlers={{ click: () => setSelectedEvent(event) }}
    >
      <Popup>
        <PlanningTooltip event={event} />
      </Popup>
    </Marker>
  )
}

export default PlanningMapMarker
