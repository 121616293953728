/* Framework imports -------------------------------------------------------- */
import React from 'react'

/* Component imports -------------------------------------------------------- */
import {
  Field,
  useField,
} from 'formik'
import { Autocomplete } from 'formik-mui'
import { TextField } from '@mui/material'

/* Type imports ------------------------------------------------------------- */
import type { FieldAttributes } from 'formik'
import type { AutocompleteRenderInputParams } from '@mui/material'

/* Component declaration ---------------------------------------------------- */
interface AutocompleteFieldProps {
  name: string;
  options: (string | number)[];
  placeholder?: string;
  size?: string;
  getOptionLabel?: (option: string | number) => string;
  isOptionEqualToValue?: (option: string, value: string) => boolean;
  multiple?: boolean;
  autoSelect?: boolean;
}

const AutocompleteField: React.FC<FieldAttributes<AutocompleteFieldProps>> = ({
  name,
  placeholder = 'Sélectionnez',
  options,
  getOptionLabel = (option: string): string => option || '',
  isOptionEqualToValue = (option: string, value: string): boolean => option === value || value === '',
  autoSelect = false,
  ...props
}) => {
  const [ , meta ] = useField(name)

  return (
    <Field
      component={Autocomplete}
      name={name}
      options={options}
      autoSelect={autoSelect}
      autoComplete
      autoHighlight
      getOptionLabel={getOptionLabel}
      isOptionEqualToValue={isOptionEqualToValue}
      renderInput={
        (params: AutocompleteRenderInputParams): React.ReactElement => (
          <TextField
            {...params}
            name={name}
            error={meta.touched && meta.error !== undefined}
            helperText={meta.touched && meta.error?.toString()}
            placeholder={placeholder}
            variant="outlined"
          />
        )
      }
      {...props}
    />
  )
}

export default AutocompleteField
