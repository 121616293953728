/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import DateUtils from 'helpers/DateUtils'
import { getCaseStatusColor } from 'helpers/caseUtils'

/* Component imports -------------------------------------------------------- */
import {
  Card,
  Link,
} from '@mui/material'
import ColoredSquareChip from 'components/ColoredSquareChip/ColoredSquareChip'

/* Type imports ------------------------------------------------------------- */
import type { DossierRecherche } from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const CardContainer = styled(Card)`
  margin: 10px 0px;
`

const CardContentContainer = styled.div`
  padding: 10px;
`

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 5px;
  align-items: center;
  font-weight: bold;
  margin-bottom: 5px;
`

/* Component declaration ---------------------------------------------------- */
interface CaseListMobileCardProps {
  myCase: DossierRecherche;
  handleNavigate: () => void;
}

const CaseListMobileCard: React.FC<CaseListMobileCardProps> = ({
  myCase,
  handleNavigate,
}) => {

  return (
    <CardContainer>
      <CardContentContainer>
        <TitleContainer>
          <Link onClick={handleNavigate}>
            {myCase.id}
          </Link>
          <ColoredSquareChip
            color={getCaseStatusColor(myCase.statut.code)}
            smaller
          >
            {myCase.statut.libelle}
          </ColoredSquareChip>
        </TitleContainer>
        {`Assuré : ${myCase.assure}`}
        <br />
        {`Donneur d'ordre : ${myCase.compagnieDAssurance?.nom || ''}`}
        <br />
        {`Ref mandant : ${myCase.id}`}
        <br />
        {`Date missionnement : ${DateUtils.APIStrToLocalDateString(myCase.dateMissionnement)}`}
        <br />
        {`Ville : ${myCase.ville}`}
        <br />
        {`Type sinistre : ${myCase.natureSinistre.libelle}`}
      </CardContentContainer>
    </CardContainer>
  )
}

export default CaseListMobileCard
