/* Module imports ----------------------------------------------------------- */
import { isValidString } from 'helpers/isValidString'
import { store } from 'store/store'
import { apiUrl } from 'helpers/readConfig'
import { Api } from './__generated__/Api'

/* Type imports ------------------------------------------------------------- */
import type { RequestParams } from './__generated__/Api'
import type { Login } from 'store/slices/authSlice'

/* Create the OpenAPI API instance ------------------------------------------ */
const OpenAPI: Api<Login> = new Api(
  {
    baseUrl: apiUrl,
    baseApiParams: { secure: true },
    securityWorker: (): RequestParams | undefined => {
      const token = store.getState().auth.token

      if (isValidString(token)) {
        return { headers: { 'Authorization': `Bearer ${token}` }}
      }
    },
  },
)

/* Export OpenAPI API instance ---------------------------------------------- */
export default OpenAPI
