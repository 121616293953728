/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useState,
} from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import {
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom'
import { caseTabs } from 'helpers/caseUtils'

/* Component imports -------------------------------------------------------- */
import {
  Tab,
  Tabs,
} from '@mui/material'

/* Styled components -------------------------------------------------------- */
const TabsContainer = styled(Tabs)`
  margin-bottom: 10px;
  min-height: 40px;

  .MuiTabs-scrollButtons {
    width: 15px;
  }

  button {
    padding-top: 0px;
    padding-bottom: 5px;
    height: 30px;
    min-height: 30px;
  }

  @media ${(props) => props.theme.media.mobile.portrait} {
    padding-top: 20px;
  }

  @media ${(props) => props.theme.media.tablet}, ${(props) => props.theme.media.mobile.main} {
    span {
      left: 0px;
      width: 5px;
    }

    button {
      align-items: start;
      padding-left: 20px;
    }
  }
`

const Divider = styled.div`
  height: 2px;
  width: 100%;
  background-color: ${(props) => props.theme.colors.grey};
  margin-top: -14px;
  margin-bottom: 15px;
`

/* Component declaration ---------------------------------------------------- */
interface CaseLayoutNavTabsProps {}

const CaseLayoutNavTabs: React.FC<CaseLayoutNavTabsProps> = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const { caseId = '' } = useParams<{caseId: string}>()
  const [ tabValue, setTabValue ] = useState<number | false>(false)

  const filteredTabs = caseTabs.filter((tab) => !tab.hide)

  useEffect(() => {
    const newTabValue = filteredTabs.findIndex((tab) => location.pathname.indexOf(tab.path) !== -1)
    setTabValue(newTabValue)
  }, [ location.pathname ])

  const handleTabChange = (newValue: number, path: string): void => {
    setTabValue(newValue)
    navigate(`/dossiers/${caseId}/${path}`)
  }

  return (
    <React.Fragment>
      <TabsContainer
        value={tabValue === -1 ? false : tabValue}
        variant="scrollable"
      >
        {
          filteredTabs.map((tab, index) => (
            <Tab
              key={`${tab.path}-${index}`}
              onClick={() => handleTabChange(index, tab.path)}
              label={tab.label}
            />
          ))
        }
      </TabsContainer>
      <Divider />
    </React.Fragment>
  )
}

export default CaseLayoutNavTabs
