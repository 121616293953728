/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import {
  Outlet,
  useParams,
} from 'react-router-dom'
import {
  useGetCaseDocumentListQuery,
  useGetCaseInfosQuery,
} from 'store/api'
import {
  useAppDispatch,
  useAppSelector,
} from 'store/hooks'
import {
  getLayout,
  setLeftWidth,
  setRightWidth,
} from 'store/slices/layoutSlice'

/* Component imports -------------------------------------------------------- */
import {
  Card,
  CircularProgress,
} from '@mui/material'
import {
  AssignmentTurnedIn,
  CalendarMonth,
  Email,
  InfoRounded,
  InsertDriveFile,
  MoreHoriz,
  MoreVert,
  Sms,
  StickyNote2,
} from '@mui/icons-material'
import LargeTitle from 'components/LargeTitle/LargeTitle'
import CustomIconButton from 'components/IconButtons/CustomIconButton/CustomIconButton'
import CaseLayoutSummary from './CaseLayoutComponents/CaseLayoutSummary'
import CaseLayoutNavTabs from './CaseLayoutComponents/CaseLayoutNabTabs'
import CaseLayoutMedia from './CaseLayoutMedia/CaseLayoutMedia'
import CaseLayoutDraggableDocument from './CaseLayoutMedia/CaseLayoutMediaDraggableDocument'

/* Styled components -------------------------------------------------------- */
const LargeTitleNoMargin = styled(LargeTitle)`
  margin-bottom: 0px;
`

const Actions = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;

  @media ${(props) => props.theme.media.mobile.portrait} {
    flex-direction: column;
    gap: 0px;
  }
`

const ActionButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;

  @media ${(props) => props.theme.media.mobile.main} {
    gap: 0px;
  }
`

const SummaryContainer = styled(Card)`
  padding: 10px 20px;
  overflow-y: auto;
  max-height: calc(100svh - 40px);
`

const CaseLayoutSummaryOutletContainer = styled.div`
  display: flex;
  height: calc(100svh - 20px);
  width: 100%;
  overflow: hidden;
`

const Resize = styled.div`
  width: 12px;
  background-color: ${(props) => props.theme.palette.info.main};
  border-radius: 20px;
  height: calc(100svh - 40px);
  display: flex;
  align-items: center;
  cursor: col-resize;

  :hover {
    opacity: 0.5;
  }
`

const Icon = styled(MoreVert)`
  color: ${(props) => props.theme.colors.main};
  margin-left: -6px;
`

interface SizeProps {
  size: number;
}

const Left = styled.div<SizeProps>`
  width: ${(props) => props.size}px;
  min-width: ${(props) => props.size}px;
  max-width: ${(props) => props.size}px;
  overflow: hidden;
  padding-right: 5px;
  display: ${(props) => props.size <= 10 ? 'none' : 'initial'};
`

const Middle = styled.div<SizeProps>`
  width: ${(props) => props.size}px;
  min-width: ${(props) => props.size}px;
  max-width: ${(props) => props.size}px;
  overflow-x: hidden;
  padding-right: 5px;
  padding-left: 5px;
`

const Right = styled.div<SizeProps>`
  width: ${(props) => props.size}px;
  min-width: ${(props) => props.size}px;
  max-width: ${(props) => props.size}px;
  overflow-x: hidden;
  padding-left: 5px;
  display: ${(props) => props.size <= 39 ? 'none' : 'initial'};

  .media-close-button {
    display: ${(props) => props.size <= 70 ? 'none' : 'initial'};
  }
`

/* Component declaration ---------------------------------------------------- */
interface CaseLayoutProps {}

const CaseLayout: React.FC<CaseLayoutProps> = () => {
  const dispatch = useAppDispatch()
  const { caseId = '' } = useParams<{caseId: string}>()
  const { leftWidth, rightWidth, documentList } = useAppSelector(getLayout)
  const [ leftMouseDown, setLeftMouseDown ] = useState<boolean>(false)
  const [ rightMouseDown, setRightMouseDown ] = useState<boolean>(false)
  const ref = useRef<HTMLDivElement | null>(null)
  const offset = useMemo(() => ref.current?.offsetLeft || 270, [ ref ])
  const rightDivWidth = useMemo(() => window.innerWidth - leftWidth - rightWidth - offset, [ window.innerWidth, leftWidth, rightWidth, offset ])

  const { currentData: caseInfos } = useGetCaseInfosQuery(caseId)
  const { currentData: documents = []} = useGetCaseDocumentListQuery({ dossier: caseId, AvecBrouillon: false })

  useEffect(() => {
    if ((leftWidth + rightWidth + offset + 39) > window.innerWidth) {
      dispatch(setRightWidth(window.innerWidth - offset - leftWidth - 39))
    }
  }, [ leftWidth, rightWidth, offset, window.innerWidth ])

  const handleMouseUp = () => {
    setLeftMouseDown(false)
    setRightMouseDown(false)
  }

  useEffect(() => {
    document.addEventListener('mouseup', () => handleMouseUp())
  }, [ document ])

  const handleMouseLeave = () => {
    if (leftMouseDown && leftWidth < 100) {
      dispatch(setLeftWidth(0))
    } else if (rightMouseDown && rightDivWidth < 100) {
      dispatch(setRightWidth(window.innerWidth - leftWidth - offset))
    }
  }

  const handleLeftMouseDown = (event: React.MouseEvent | React.TouchEvent) => {
    setLeftMouseDown(true)
    event.preventDefault()
  }

  const handleRightMouseDown = (event: React.MouseEvent | React.TouchEvent) => {
    setRightMouseDown(true)
    event.preventDefault()
  }

  const handleMove = (pageX: number) => {
    if (leftMouseDown) {
      const size = pageX - offset <= 400 ? pageX - offset : 400
      dispatch(setLeftWidth(size))
    } else if (rightMouseDown) {
      dispatch(setRightWidth(pageX - leftWidth - offset))
    }

    const timer = setTimeout(() => {
      window.dispatchEvent(new Event('resize'))
    }, 500)

    return () => clearTimeout(timer)
  }

  const handleMouseMove = (event: React.MouseEvent) => {
    handleMove(event.pageX)
  }

  const handleTouchMove = (event: React.TouchEvent) => {
    if (event.touches[0].pageX) {
      handleMove(event.touches[0].pageX)
    }
  }

  const toggleDrawer = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    event.stopPropagation()
    dispatch(setLeftWidth(leftWidth < 10 ? 320 : 0))
  }

  return (
    <CaseLayoutSummaryOutletContainer
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseLeave}
      onMouseMove={handleMouseMove}
      onTouchMoveCapture={handleTouchMove}
      onTouchEndCapture={handleMouseUp}
      ref={ref}
    >
      <Left size={leftWidth}>
        <SummaryContainer>
          {
            !caseInfos ?
              <CircularProgress color="secondary" /> :
              <CaseLayoutSummary
                summary={caseInfos}
                documents={documents}
              />
          }
        </SummaryContainer>
      </Left>
      <Resize
        onMouseDown={handleLeftMouseDown}
        onTouchStartCapture={handleLeftMouseDown}
      >
        <Icon />
      </Resize>
      <Middle size={rightWidth}>
        <LargeTitleNoMargin>
          <Actions>
            {caseId}
            <ActionButtons>
              <CustomIconButton
                Icon={InfoRounded}
                onClick={toggleDrawer}
                variant="outlined"
                color="secondary"
              />
              <CustomIconButton
                Icon={CalendarMonth}
                onClick={() => null}
                variant="contained"
              />
              <CustomIconButton
                Icon={AssignmentTurnedIn}
                onClick={() => null}
                variant="contained"
              />
              <CustomIconButton
                Icon={InsertDriveFile}
                onClick={() => null}
                variant="contained"
              />
              <CustomIconButton
                Icon={Email}
                onClick={() => null}
                variant="contained"
              />
              <CustomIconButton
                Icon={Sms}
                onClick={() => null}
                variant="contained"
              />
              <CustomIconButton
                Icon={StickyNote2}
                onClick={() => null}
                variant="contained"
              />
              <CustomIconButton
                Icon={MoreHoriz}
                onClick={() => null}
                variant="contained"
              />
            </ActionButtons>
          </Actions>
        </LargeTitleNoMargin>
        <CaseLayoutNavTabs />
        <Outlet />
      </Middle>
      <Resize
        onMouseDown={handleRightMouseDown}
        onTouchStart={handleRightMouseDown}
      >
        <Icon />
      </Resize>
      <Right size={rightDivWidth}>
        <CaseLayoutMedia documents={documents} />
      </Right>
      {
        documentList[caseId]?.map((document, index) => (
          <CaseLayoutDraggableDocument
            doc={document}
            key={`${document.id}-${index}`}
          />
        ))
      }
    </CaseLayoutSummaryOutletContainer>
  )
}

export default CaseLayout
