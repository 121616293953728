/* Type imports ------------------------------------------------------------- */
import type { Document } from 'API/__generated__/Api'

/* Type declarations -------------------------------------------------------- */
export type FileType = 'img' | 'pdf' | 'word' | 'other'

/* Helper declarations ------------------------------------------------------ */
export const getMediaFileType = (document: Document): FileType => {
  const fileType = document.contentType.split('/')

  if (fileType[0] === 'image') {
    return 'img'
  }
  else if (fileType[1] === 'msword') {
    return 'word'
  }
  else if (fileType[1] === 'pdf') {
    return 'pdf'
  }

  return 'other'
}

export const isEditableFileType = (document: Document): boolean => {
  return (getMediaFileType(document) === 'img' || getMediaFileType(document) === 'pdf')
}
