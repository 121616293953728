/* Framework imports -------------------------------------------------------- */
import React, { useMemo } from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import {
  CalendarViewTypes,
  defaultFullCalendarOptions,
  joursFeries,
} from 'helpers/FullCalendarOptions'
import DateUtils from 'helpers/DateUtils'

/* Component imports -------------------------------------------------------- */
import FullCalendar from '@fullcalendar/react' /* Must be imported before other FullCalendar plugins */
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'
import resourceTimelinePlugin from '@fullcalendar/resource-timeline'
import PlanningCalendarEventRender from './PlanningCalendarEventRender/PlanningCalendarEventRender'

/* Type imports ------------------------------------------------------------- */
import type {
  DateSelectArg,
  DayHeaderContentArg,
  EventChangeArg,
  EventClickArg,
  EventInput,
  SlotLabelContentArg,
} from '@fullcalendar/core'
import type {
  EventHandlerActionType,
  EventHandlerType,
} from 'types/EventHandler'
import type {
  IntervenantPlanning,
  Planning,
} from 'types/Planning'
import type { ResourceSourceInput } from '@fullcalendar/resource'

/* Internal variables ------------------------------------------------------- */
const plugins = [
  dayGridPlugin,
  timeGridPlugin,
  listPlugin,
  interactionPlugin,
  resourceTimelinePlugin,
]

/* Styled components -------------------------------------------------------- */
interface HeaderContentProps {
  view: CalendarViewTypes;
}

const HeaderContent = styled.div<HeaderContentProps>`
  display: ${(props) => {
    switch (props.view) {
      case CalendarViewTypes.HorizontalWeek:
      case CalendarViewTypes.HorizontalBusy:
      case CalendarViewTypes.HorizontalDay:
      case CalendarViewTypes.List:
        return 'flex'
      default:
        return 'initial'
    }}};
  font-size: ${(props) => {
    switch (props.view) {
      case CalendarViewTypes.HorizontalWeek:
      case CalendarViewTypes.HorizontalBusy:
      case CalendarViewTypes.HorizontalDay:
        return '.95rem'
      default:
        return '1rem'
    }}};

  text-transform: uppercase;
  font-weight: normal;
  align-items: center;
  gap: 10px;

  .day-number {
    font-size: ${(props) => {
    switch (props.view) {
      case CalendarViewTypes.List:
        return '1rem'
      case CalendarViewTypes.HorizontalWeek:
      case CalendarViewTypes.HorizontalBusy:
      case CalendarViewTypes.HorizontalDay:
        return '.95rem'
      default:
        return '1.2rem'
    }}};
    font-weight: bold;
  }
`

const LabelContainer = styled.div`
  background-color: ${(props) => props.color};
  border-radius: 4px;
  width: fit-content;
  padding: 2px 4px;
`

const Calendar = styled.div`
  height: 100%;
  color: ${(props) => props.theme.palette.secondary.main};

  .fc {
    background-color: white;
    height: 100%;
  }

  .fc .fc-bg-event {
    background-color: ${(props) => props.theme.colors.darkgrey}22 !important;
    color: ${(props) => props.theme.colors.darkgrey} !important;
    opacity: 1 !important;
  }

  .fc-daygrid table.fc-scrollgrid {
    border-collapse: separate;
  }

  a.fc-event {
    border: none;
  }

  a.fc-daygrid-event.fc-daygrid-dot-event.fc-event {
    margin: 0;
  }

  a.fc-daygrid-event.fc-daygrid-block-event.fc-event {
    border: none;
    margin: 2px 0px !important;
  }

  .fc-direction-ltr .fc-timegrid-col-events {
    margin: 0;
  }

  .fc-timegrid-event {
    margin-bottom: 0px;
  }

  .fc-timegrid-event .fc-event-main {
    padding: 0;
  }

  .fc .week-view {
    height: 55px;
  }

  .fc .day-view {
    height: 73px;
  }

  .fc .fc-list-table td {
    padding: 0px 0px 0px 10px;
  }

  .fc-list-event-time {
    align-content: center;
  }

  .fc-list-event-dot {
    display: none;
  }
`

/* Component declaration ---------------------------------------------------- */
interface PlanningCalendarProps {
  initialView?: string;
  initialDate: Date;
  calendarRef: React.RefObject<FullCalendar>;
  events: Planning[];
  stakeholders: IntervenantPlanning[];
  setEventHandler?: (values: EventHandlerType) => void;
  onEventChange?: (type?: EventHandlerActionType | null, newEvent?: Planning) => void;
}

const PlanningCalendar: React.FC<PlanningCalendarProps> = ({
  initialView,
  initialDate,
  calendarRef,
  events,
  stakeholders,
  setEventHandler,
  onEventChange,
}) => {

  const editEvent = (e: EventClickArg): void => {
    if (e?.event?.startEditable) {
      setEventHandler &&
      setEventHandler(
        {
          open: true,
          action: 'edit',
          event: events.find((event) => e.event.id === event.id),
          dates: null,
        },
      )
    }
  }

  const addEvent = (e: DateSelectArg): void => {
    setEventHandler &&
    setEventHandler(
      {
        open: true,
        action: 'add',
        event: undefined,
        dates: e,
        stakeholderId: e.resource?.id,
      },
    )
  }

  const eventChange = (e: EventChangeArg): void => {
    const newEvent: Planning = {
      ...events.find((event) => event.id === e.event.id) as Planning,
      ...e.event,
      id: e.event.id ??`${e.event.start?.toISOString()}-${e.event.end?.toISOString()}`,
      dateDebut: e.event.start?.toISOString() as string,
      dateFin: e.event.end?.toISOString() as string,
    }
    onEventChange && onEventChange('edit', newEvent)
  }

  const calendarFullYear = calendarRef.current?.getApi().getDate().getFullYear()
  const viewType = calendarRef.current?.getApi()?.view?.type as CalendarViewTypes

  const holidays = useMemo(() => joursFeries(calendarFullYear ?? new Date().getFullYear()), [ calendarFullYear ])
  const allEvents: EventInput[] = useMemo(() => [
    ...events.map((event: Planning): EventInput => ({
      start: event.dateDebut,
      end: event.dateFin,
      title: event.assure || event.typeRDV.libelle,
      resourceIds: [ ...event.intervenants?.map((i) => i.id) ?? [] ],
      editable: true,
      id: event.id,
      backgroundColor: event.typeRDV?.color,
      allDay: event.journeeComplete ?? false,
    })),
    ...Object.keys(holidays).map((holiday): EventInput => ({
      start: holidays[holiday].toISOString(),
      title: holiday,
      id: holiday,
      display: 'background',
      allDay: true,
    })),
  ], [ events, holidays ])
  const ressources: ResourceSourceInput = useMemo(() => stakeholders.map((stakeholder) => ({
    id: stakeholder.id,
    title: `${stakeholder.prenom || ''} ${stakeholder.nom}`,
    color: stakeholder.color,
  })), [ allEvents, stakeholders ])

  const headerContent = (headerType: 'slot' | 'day') => (event: SlotLabelContentArg | DayHeaderContentArg) => {
    if (headerType === 'slot' && viewType !== CalendarViewTypes.HorizontalWeek && viewType !== CalendarViewTypes.HorizontalBusy)
      return event.text
    if (((viewType === CalendarViewTypes.HorizontalWeek || viewType === CalendarViewTypes.HorizontalBusy) && event.level !== 0) || viewType === CalendarViewTypes.HorizontalDay)
      return event.text

    return (
      <HeaderContent view={viewType}>
        {DateUtils.APIStrToLocalDateString(event.date.toISOString(), { weekday: 'long' })}
        {
          viewType !== CalendarViewTypes.Month &&
            <div className="day-number">
              {DateUtils.APIStrToLocalDateString(event.date.toISOString(), { day: '2-digit', 'month': '2-digit' })}
            </div>
        }
      </HeaderContent>
    )
  }

  return (
    <Calendar>
      <FullCalendar
        ref={calendarRef}
        {...defaultFullCalendarOptions}
        plugins={plugins}
        schedulerLicenseKey={process.env.REACT_APP_FULLCALENDAR_KEY || ''}
        initialView={initialView}
        initialDate={initialDate}
        slotLaneClassNames={viewType === CalendarViewTypes.Day ? 'day-view' : 'week-view'}
        slotLabelContent={() => headerContent('slot')}
        dayHeaderContent={() => headerContent('day')}
        resourceAreaColumns={
          [
            {
              field: 'title',
              headerContent: 'Intervenants',
              cellContent: (e) => (
                <LabelContainer color={e.resource?.extendedProps?.color as string || ''}>
                  {e.resource?.title}
                </LabelContainer>
              ),
            },
          ]
        }
        events={allEvents}
        resources={ressources}
        eventContent={
          (eventInfo) => (
            <PlanningCalendarEventRender
              eventInfo={eventInfo}
              event={events.find((evt) => evt.id === eventInfo.event.id)}
              viewType={viewType}
            />
          )
        }
        eventChange={eventChange}
        eventClick={editEvent}
        select={addEvent}
      />
    </Calendar>
  )
}

export default PlanningCalendar
