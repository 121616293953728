/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useRef,
  useState,
} from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import {
  useNavigate,
  useParams,
} from 'react-router-dom'
import { Calendar } from '@fullcalendar/core'
import timeGridPlugin from '@fullcalendar/timegrid'
import { defaultFullCalendarOptions } from 'helpers/FullCalendarOptions'
import DateUtils from 'helpers/DateUtils'
import {
  useAppDispatch,
  useAppSelector,
} from 'store/hooks'
import {
  getPlanningState,
  setPlanningStartDate,
} from 'store/slices/planningSlice'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  Card,
  CardContent,
} from '@mui/material'
import PageContainer from 'layouts/PageContainer/PageContainer'
import SegmentedButtons from 'components/SegmentedButtons/SegmentedButtons'
import LargeTitle from 'components/LargeTitle/LargeTitle'
import PlanningCalendarButtons from 'pages/PlanningPages/PlanningComponents/PlanningCalendarButtons'
import PlanningEventList from 'pages/PlanningPages/PlanningComponents/PlanningEventList'
import PlanningMap from './PlanningMap/PlanningMap'
import PlanningFilters from '../PlanningComponents/PlanningFilters'

/* Type imports ------------------------------------------------------------- */
import type { Map } from 'leaflet'
import type { SegmentedButtonOption } from 'components/SegmentedButtons/SegmentedButtons'
import type { Planning } from 'types/Planning'
import {
  currentPlanningData,
  currentStakeholders,
  currentTypes,
} from 'types/Planning'

/* Type declarations -------------------------------------------------------- */
type PlanningMapDay = 'lundi' | 'mardi' | 'mercredi' | 'jeudi' | 'vendredi' | 'samedi'

/* Internal variables ------------------------------------------------------- */
const days: PlanningMapDay[] = [ 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi' ]

const calendarElmt: HTMLElement = document.createElement('div')
calendarElmt.id = 'mock-full-calendar'

/* Styled components -------------------------------------------------------- */
const SegmentedButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 260px auto;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: -10px;
  color: ${(props) => props.theme.palette.secondary.main};

  #planning-map-current-day {
    text-transform: uppercase;
    font-weight: bold;
  }

  @media ${(props) => props.theme.media.tablet} {
    grid-template-columns: 1fr auto auto;
  }

  @media ${(props) => props.theme.media.mobile.main} {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
`

const FilterAndMap = styled.div`
  display: flex;
  gap: 15px;
  margin-top: 15px;
  height: calc(100vh - 230px);

  @media ${(props) => props.theme.media.desktop} {
    height: calc(100vh - 175px);
  }

  @media ${(props) => props.theme.media.mobile.main} {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
`

const MapContainer = styled.div`
  width: 100%;
  overflow-y: scroll;

  @media ${(props) => props.theme.media.mobile.main} {
    overflow-y: hidden;
  }
`

const DateContainer = styled.div`
  display: flex;
  gap: 10px;
`

/* Component declaration ---------------------------------------------------- */
interface PlanningMapPageProps {}

const PlanningMapPage: React.FC<PlanningMapPageProps> = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const planningState = useAppSelector(getPlanningState)
  const { eventDate, eventId } = useParams<{eventDate: string; eventId: string}>()
  const calendarRef: React.MutableRefObject<Calendar> = useRef<Calendar>(
    new Calendar(
      calendarElmt,
      {
        ...defaultFullCalendarOptions,
        plugins: [ timeGridPlugin ],
        initialView: 'timeGridWeek',
        initialDate: eventDate && eventId ? new Date(eventDate) : planningState?.startDate ? new Date(planningState.startDate) : new Date(),
      },
    ),
  )
  const [ mapRef, setMapRef ] = useState<Map | null>(null)
  const [ selectedEvent, setSelectedEvent ] = useState<string>('')
  const [ currentDay, setCurrentDay ] = useState<Date>(new Date())
  const [ rdvFilters, setRdvFilters ] = useState<string[]>([])
  const [ stakeholderFilters, setStakeholdersFilters ] = useState<string[]>([])

  useEffect(() => {
    if (eventDate) {
      setCurrentDay(new Date(eventDate))
      if (eventId) {
        setSelectedEvent(eventId)
      }
    } else if (planningState.startDate) {
      setCurrentDay(new Date(planningState.startDate))
    } else {
      setCurrentDay(new Date())
    }
  }, [])

  const updateCalendarView = (today: boolean = false): void => {
    if (calendarRef !== null) {
      dispatch(setPlanningStartDate(calendarRef.current.view.currentStart.toISOString()))
      if (today) {
        setCurrentDay(new Date())
      }
      else {
        setCurrentDay(calendarRef.current.view.currentStart)
      }
    }
  }

  const onPreviousClicked: React.MouseEventHandler<HTMLButtonElement> = () => {
    if (calendarRef !== null) {
      calendarRef.current.prev()
      updateCalendarView()
    }
  }

  const onNextClicked: React.MouseEventHandler<HTMLButtonElement> = () => {
    if (calendarRef !== null) {
      calendarRef.current.next()
      updateCalendarView()
    }
  }

  const onTodayClicked: React.MouseEventHandler<HTMLButtonElement> = () => {
    if (calendarRef !== null) {
      calendarRef.current.today()
      updateCalendarView(true)
    }
  }

  const onDateClicked = (value: Date | null) => {
    if (calendarRef !== null && value !== null) {
      calendarRef.current.gotoDate(value)
      updateCalendarView()
    }
  }

  const onCalendarOptionClick = (pOption: PlanningMapDay): void => {
    const newDay: Date = new Date(calendarRef.current.view.activeStart)
    const currentDay = new Date(newDay.setDate(newDay.getDate() + days.indexOf(pOption)))
    setCurrentDay(currentDay)
    dispatch(setPlanningStartDate(currentDay.toISOString()))
  }

  const filterEvents = (event: Planning): boolean => {
    const { activeStart, activeEnd } = calendarRef.current.view
    const startDate: Date = DateUtils.APIStrToDate(event.dateDebut)
    const endDate: Date = DateUtils.APIStrToDate(event.dateFin)

    return startDate >= activeStart && endDate < activeEnd && startDate.getDate() === currentDay.getDate() &&
    (!rdvFilters?.length || rdvFilters.includes(event.typeRDV?.id)) &&
    (!stakeholderFilters?.length || stakeholderFilters.some((filter) => event.intervenants?.find((stakeholder) => stakeholder.id === filter)))
  }

  const setSelectedEventAndScroll = (event: Planning, scrollToTop: boolean = false): void => {
    const view: HTMLElement | null = document.getElementById('calendar-map-view')

    if (scrollToTop) {
      view?.scroll({ top: 150, left: 0, behavior: 'smooth' })
    }

    setSelectedEvent(event.id)
  }

  const getPrettyDate = (pDate: Date): string => {
    const dateStr: string = pDate.toLocaleDateString(
      'fr',
      {
        weekday: 'long',
        month: 'long',
        day: 'numeric',
        year: 'numeric',
      },
    )

    return dateStr
  }

  const filteredEvents: Planning[] = currentPlanningData.filter((e: Planning): boolean => filterEvents(e)).sort((a, b) => a.dateDebut.localeCompare(b.dateDebut))

  const calendarOptions: SegmentedButtonOption<string>[] = days.map((option) => ({ value: option, label: option.charAt(0).toUpperCase() + option.slice(1) }))

  return (
    <PageContainer id="calendar-map-view">
      <Card>
        <CardContent>
          <LargeTitle>
            Carte
            <DateContainer>
              <PlanningCalendarButtons
                calendarViewTitle={calendarRef?.current.view.title || ''}
                onPreviousClicked={onPreviousClicked}
                onTodayClicked={onTodayClicked}
                onNextClicked={onNextClicked}
                dateToClick={calendarRef?.current?.view.currentStart}
                onDateClicked={onDateClicked}
              />
            </DateContainer>
          </LargeTitle>
          <SegmentedButtonsContainer>
            <SegmentedButtons
              options={calendarOptions}
              selectedOption={currentDay.toLocaleDateString('fr', { weekday: 'long' })}
              setSelectedOption={onCalendarOptionClick as ((pOption: string) => void)}
              smaller
            />
            <div id="planning-map-current-day">
              {getPrettyDate(currentDay)}
            </div>
            <Button
              variant="contained"
              onClick={() => navigate('/planning')}
              fullWidth
            >
              Vue planning
            </Button>
          </SegmentedButtonsContainer>
        </CardContent>
      </Card>
      <FilterAndMap>
        <PlanningFilters
          rdvFilters={rdvFilters}
          setRdvFilters={setRdvFilters}
          stakeholderFilters={stakeholderFilters}
          setStakeholdersFilters={setStakeholdersFilters}
          rdvTypes={currentTypes}
          stakeholders={currentStakeholders}
        />
        <MapContainer>
          <PlanningMap
            events={filteredEvents}
            mapRef={mapRef}
            setMapRef={setMapRef}
            setSelectedEvent={setSelectedEventAndScroll}
            selectedEvent={selectedEvent}
          />
          <PlanningEventList
            events={filteredEvents}
            setSelectedEvent={setSelectedEventAndScroll}
            selectedEvent={selectedEvent}
            displayOrder
          />
        </MapContainer>
      </FilterAndMap>
    </PageContainer>
  )
}

export default PlanningMapPage
