/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import DateUtils from 'helpers/DateUtils'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  Card,
  Link,
} from '@mui/material'

/* Type imports ------------------------------------------------------------- */
import type { PieceJointe } from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const CardContainer = styled(Card)`
  margin: 10px 0px;
`

const CardContentContainer = styled.div`
  padding: 10px;
`

const FileData = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 15px;
`

const TitleContainer = styled.div`
  display: flex;
  gap: 5px;
  align-items: center;
  font-weight: bold;
  margin-bottom: 5px;
`

/* Component declaration ---------------------------------------------------- */
interface CaseTaskMobileTableCardProps {
  file: PieceJointe;
}

const CaseTaskMobileTableCard: React.FC<CaseTaskMobileTableCardProps> = ({ file }) => {

  return (
    <CardContainer>
      <CardContentContainer>
        <TitleContainer>
          <Link>
            {file.libelle}
          </Link>
        </TitleContainer>
        <FileData>
          <div>
            {`Échéance : ${DateUtils.APIStrToLocalDateString()}`}
            <br />
            {`Type : ${file.libelle}`}
            <br />
            {`Libellé : ${file.libelle}`}
            <br />
            {`Destinataire : ${file.libelle}`}
            <br />
            <div>
              <Button variant="outlined">
                Transférer
              </Button>
              <Button variant="outlined">
                Reporter
              </Button>
              <Button variant="contained">
                Solder
              </Button>
            </div>
          </div>
        </FileData>
      </CardContentContainer>
    </CardContainer>
  )
}

export default CaseTaskMobileTableCard
