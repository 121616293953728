/* Module imports ----------------------------------------------------------- */
import { isValidString } from './isValidString'

/* Type imports ------------------------------------------------------------- */
import { LatLng } from 'leaflet'
import type { Adresse } from 'API/__generated__/Api'
import type { LocalisationGPS } from 'types/Planning'

export const getCoordinates = (coordinates?: LocalisationGPS): LatLng => {
  let lat: number = 0
  let lon: number = 0

  if (coordinates) {
    if (coordinates.latitude !== null && coordinates.latitude !== undefined && !isNaN(coordinates.latitude)) {
      lat = coordinates.latitude
    }
    if (coordinates.longitude !== null && coordinates.longitude !== undefined && !isNaN(coordinates.longitude)) {
      lon = coordinates.longitude
    }
  }

  return (new LatLng(lat, lon))
}

export const hasCoordinates = (coordinates?: LocalisationGPS): boolean => {
  if (!coordinates ||
    coordinates.latitude === null || coordinates.latitude === undefined || isNaN(coordinates.latitude) ||
    coordinates.longitude === null || coordinates.longitude === undefined || isNaN(coordinates.longitude)
  ) {
    return false
  }
  return true
}

export const hasAddress = (address?: Adresse): boolean => isValidString(address?.adresse1) && isValidString(address?.codePostal) && isValidString(address?.ville)
