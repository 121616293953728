/* Component imports -------------------------------------------------------- */
import { WorkflowEtat } from 'API/__generated__/Api'
import CaseHistoryPage from 'pages/CaseHistoryPage/CaseHistoryPage'
import CaseInvoicePage from 'pages/CaseInvoicePage/CaseInvoicePage'
import CaseMeasurePage from 'pages/CaseMeasurePage/CaseMeasurePage'
import CaseOrderPage from 'pages/CaseOrderPage/CaseOrderPage'
import CaseQuotePage from 'pages/CaseQuotePage/CaseQuotePage'
import CaseTaskPage from 'pages/CaseTaskPage/CaseTaskPage'
import InvoicePage from 'pages/InvoicePage/InvoicePage'
import MessagesPage from 'pages/MessagesPage/MessagesPage'
import QuotePage from 'pages/QuotePage/QuotePage'

/* Type imports ------------------------------------------------------------- */
import type { ChipColor } from 'types/ChipColor'

/* Helper function declarations --------------------------------------------- */
export const getCaseStatusColor = (code: string): ChipColor => {
  switch (code) {
    case 'C':
      return 'blue'
    case 'RA':
      return 'red'
    case 'R':
      return 'purple'
    case 'D':
      return 'orange'
    case 'X':
      return 'salmon'
    case 'M':
      return 'green'
    case 'T':
      return 'grey'
    default:
      return 'yellow'
  }
}

export const getJalonStatusColor = (code: WorkflowEtat): ChipColor => {
  switch (code) {
    case WorkflowEtat.EnAttente:
      return 'yellow'
    case WorkflowEtat.Fait:
      return 'green'
    case WorkflowEtat.Brouillon:
      return 'orange'
    case WorkflowEtat.Anomalie:
      return 'red'
    case WorkflowEtat.Annule:
      return 'salmon'
    case WorkflowEtat.NonFait:
      return 'grey'
    case WorkflowEtat.NonAboutie:
      return 'yellow'
  }
}

interface CaseTab {
  label: string;
  path: string;
  component: React.ComponentType<{props: object}>;
  hide?: boolean;
}

export const caseTabs: CaseTab[] = [
  {
    path: 'historique',
    label: 'Historique',
    component: CaseHistoryPage,
  },
  {
    path: 'taches',
    label: 'Tâches',
    component: CaseTaskPage,
  },
  {
    path: 'messages',
    label: 'Messages',
    component: MessagesPage,
  },
  {
    path: 'metre',
    label: 'Métré',
    component: CaseMeasurePage,
  },
  {
    path: 'devis',
    label: 'Devis',
    component: CaseQuotePage,
  },
  {
    path: 'devis/edition/:id',
    label: 'Devis',
    component: QuotePage,
    hide: true,
  },
  {
    path: 'devis/nouveau',
    label: 'Devis',
    component: QuotePage,
    hide: true,
  },
  {
    path: 'commande',
    label: 'Commande',
    component: CaseOrderPage,
  },
  {
    path: 'facture',
    label: 'Facture',
    component: CaseInvoicePage,
  },
  {
    path: 'facture/edition/:id',
    label: 'Facture',
    component: InvoicePage,
    hide: true,
  },
  {
    path: 'facture/nouveau',
    label: 'Facture',
    component: InvoicePage,
    hide: true,
  },
  {
    path: 'cout-de-revient',
    label: 'Coût de revient',
    component: CaseHistoryPage,
  },
]
