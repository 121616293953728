/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useState,
} from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import {
  useLocation,
  useNavigate,
} from 'react-router-dom'
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import {
  useGetDepartmentListQuery,
  useGetCaseInsuranceCompanyListQuery,
  useGetCaseListQuery,
  useGetDisasterListQuery,
} from 'store/api'
import {
  exportToXLSX,
  getRowsComparator,
} from 'helpers/tableUtils'
import DateUtils from 'helpers/DateUtils'
import {
  caseTabs,
  getCaseStatusColor,
} from 'helpers/caseUtils'
import {
  useAppDispatch,
  useAppSelector,
} from 'store/hooks'
import { setRouterLastPath } from 'store/slices/routerHistorySlice'
import { getCaseListPeriod } from 'store/slices/caseListFilterSlice'
import { addCase } from 'store/slices/caseListSlice'

/* Component imports -------------------------------------------------------- */
import {
  Card,
  CardContent,
  Collapse,
  IconButton,
  Tab,
  Tabs,
} from '@mui/material'
import {
  Search,
  KeyboardArrowDownRounded,
} from '@mui/icons-material'
import { TextField } from 'formik-mui'
import Footer from 'layouts/Footer/Footer'
import LargeTitle from 'components/LargeTitle/LargeTitle'
import LongButton from 'components/LongButton/LongButton'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import ScrollableFiltersContainer from 'components/ScrollableFiltersContainer/ScrollableFiltersContainer'
import RoundedChip from 'components/RoundedChip/RoundedChip'
import Table from 'components/Table/Table'
import CheckableSortingButton from 'components/CheckableSortingButton/CheckableSortingButton'
import ColoredSquareChip from 'components/ColoredSquareChip/ColoredSquareChip'
import LoadingOverlay from 'components/Loader/LoadingOverlay'
import CaseListFilters from './CaseListComponents/CaseListFilters'
import CaseListMobileCard from './CaseListComponents/CaseListMobileCard'

/* Type imports ------------------------------------------------------------- */
import {
  Field,
  type FormikContextType,
} from 'formik'
import type {
  RechercheDossierListParams,
  CodeLabel,
  DossierRecherche,
} from 'API/__generated__/Api'
import type {
  ColumnHeader,
  DataName,
  Order,
} from 'types/Table'

/* Type declarations -------------------------------------------------------- */
const myCasesSchema = Yup.mixed<RechercheDossierListParams>().required()

type CaseListForm = FormikContextType<RechercheDossierListParams>

interface OpenedMenu {
  filter: boolean;
  sort: boolean;
}

/* Internal Variables ------------------------------------------------------- */
const periodList = [
  {
    value: new Date('2000-01-01').toLocaleString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
    label: 'Tout',
  },
  {
    value: new Date(Date.now() - 7 * 24 * 60 * 60 * 1000).toLocaleString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
    label: '7 derniers jours',
  },
  {
    value: new Date(Date.now() - 30 * 24 * 60 * 60 * 1000).toLocaleString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
    label: '30 derniers jours',
  },
  {
    value: new Date(Date.now() - 93 * 24 * 60 * 60 * 1000).toLocaleString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
    label: '3 derniers mois',
  },
  {
    value: new Date(Date.now() - 365 * 24 * 60 * 60 * 1000).toLocaleString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
    label: "L'année passée",
  },
]

const tabs = [
  {
    value: 'TousLesDossiers',
    label: 'Tous les dossiers',
  },
  {
    value: 'NouveauxDossiers',
    label: 'Nouveaux dossiers',
  },
  {
    value: 'RdvMetre',
    label: 'Rdv métré',
  },
  {
    value: 'Devis',
    label: 'Devis',
  },
  {
    value: 'Commandes',
    label: 'Commandes',
  },
  {
    value: 'RdvTravaux',
    label: 'Rdv travaux',
  },
  {
    value: 'Factures',
    label: 'Factures',
  },
]

const RESULT_LIMIT = 10

/* Styled components -------------------------------------------------------- */
const FilterDesktopContainer = styled.div`
  display: grid;
  grid-template-columns: 85% 14%;
  justify-content: space-between;
  align-items: flex-end;

  @media ${(props) => props.theme.media.mobile.main} {
    display: none;
  }

  @media ${(props) => props.theme.media.tablet} {
    grid-template-columns: 1fr;
    gap: 10px;
  }
`

const FilterMobileContainer = styled.div`
  display: none;

  @media ${(props) => props.theme.media.mobile.main} {
    display: initial;
  }
`

const FormTitleWithArrow = styled(FormBoldTitle)`
  align-items: center;
  cursor: pointer;
  margin-bottom: 0px;
  margin-top: 10px;
`

interface DropDownArrowProps {
  open: boolean;
}

const DropDownArrow = styled(KeyboardArrowDownRounded)<DropDownArrowProps>`
  transform: scaleY(${(props) => props.open ? '-1' : '1'});
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 36px;
`

const BoldSeparator = styled.div`
  margin-top: 10px;
  border-bottom: 2px solid ${(props) => props.theme.colors.lightgrey};
`

const CustomChip = styled(RoundedChip)`
  display: flex;
  justify-content: space-between;
  gap: 10px;
`

const TableCardContainer = styled(Card)`
  margin-top: 20px;

  @media ${(props) => props.theme.media.mobile.main} {
    display: none;
  }
`

const TableCardContentContainer = styled.div`
  padding: 0px 5px 5px;
`

const StatusChipContainer = styled.div`
  width: 100%;
  display: flex;

  .status-chip {
    font-size: .8rem;
    width: 100%;
  }
`

const MobileCardContainer = styled.div`
  display: none;

  @media ${(props) => props.theme.media.mobile.main} {
    display: initial;
  }
`

const Link = styled.a`
  color: ${(props) => props.theme.palette.primary.main};
`

const TabsContainer = styled(Tabs)`
  border-bottom: 2px solid ${(props) => props.theme.colors.grey};
  margin-bottom: 5px;

  .MuiTabs-scrollButtons {
    width: 15px;
  }
`

const SearchExportContainer = styled.div`
  display: flex;
  gap: 10px;
  width: 430px;

  @media ${(props) => props.theme.media.mobile.portrait} {
    flex-direction: column;
    width: 100%;
  }
`

/* Component declaration ---------------------------------------------------- */
interface CaseListPageProps {}

const CaseListPage: React.FC<CaseListPageProps> = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const location = useLocation()
  const period = useAppSelector(getCaseListPeriod)
  const [ caseList, setCaseList ] = useState<DossierRecherche[]>([])
  const [ openedMenu, setOpenedMenu ] = useState<OpenedMenu>({ filter: false, sort: false })
  const [ statusFilters, setStatusFilters ] = useState<{status: CodeLabel; amount: number | null}[]>([])
  const [ totalRows, setTotalRows ] = useState<number | undefined>()
  const [ order, setOrder ] = useState<Order>('asc')
  const [ orderBy, setOrderBy ] = useState<DataName>('')
  const [ tabValue, setTabValue ] = useState<number | boolean>(0)
  const [ selectedCases, setSelectedCases ] = useState<DossierRecherche[]>([])

  const {
    currentData: companyList = [],
    isFetching: isFetchingCompanyList,
  } = useGetCaseInsuranceCompanyListQuery()
  const {
    currentData: departmentList = [],
    isFetching: isFetchingDepartmentList,
  } = useGetDepartmentListQuery()
  const {
    currentData: disasterNatureList = [],
    isFetching: isFetchingDisasterNatureList,
  } = useGetDisasterListQuery()

  const formikForm: CaseListForm = useForm<RechercheDossierListParams>(
    {
      initialValues: {
        Limite: RESULT_LIMIT,
        CompagnieAssurance: [],
        DateDebut: period,
        DateFin: new Date().toLocaleString('en-CA', { year: 'numeric', month: '2-digit', day: '2-digit' }),
        Departement: '',
        IndexDebut: 0,
        NatureSinistre: [],
        RechercheLibre: '',
        Statut: [],
      },
      validationSchema: myCasesSchema,
    },
  )

  const parseFilters = (params: RechercheDossierListParams) => {
    const result = structuredClone(params)

    for (const key in params) {
      const typedKey = key as keyof RechercheDossierListParams
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      result[typedKey] = params[typedKey] === null ? '' : params[typedKey]
    }

    return result
  }

  const {
    currentData: caseResultList,
    isFetching: isFetchingCaseResultList,
  } = useGetCaseListQuery(parseFilters(formikForm.values))

  const saveLastPath = (caseId: string) => {
    dispatch(setRouterLastPath(location.pathname))
    dispatch(addCase({ caseId, tab: caseTabs[0].path, name: caseList.find((c) => c.id === caseId)?.assure || '' }))
  }

  const handleNavigate = (caseId: string) => {
    saveLastPath(caseId)
    navigate(`${caseId}/${caseTabs[0].path}`)
  }

  useEffect(() => {
    if (caseResultList?.resultats?.length !== undefined) {
      setCaseList(caseResultList?.resultats?.slice().sort(getRowsComparator(order, orderBy)) ?? [])
    }
    if (caseResultList?.totalResultats !== undefined && caseResultList.totalResultats !== totalRows) {
      setTotalRows(caseResultList.totalResultats)
    }
    if (caseResultList?.nombreResultatParStatut) {
      setStatusFilters(
        caseResultList?.nombreResultatParStatut?.map(({ nombreResultat, statutComposeDossier }) => ({
          status: statutComposeDossier ?? { code: '', libelle: '' },
          amount: nombreResultat || 0,
        })),
      )
    }
  }, [ caseResultList ])

  const cols: ColumnHeader[] = [
    {
      id: 'assure',
      label: 'Assuré',
    },
    {
      id: 'compagnieDAssurance.nom',
      label: "Donneur d'ordre",
    },
    {
      id: 'id',
      label: 'Ref mandant',
      render: (companyRef: string, row) => (
        <Link
          href={`/dossiers/${(row as DossierRecherche).id}/${caseTabs[0].path}`}
          onClick={(e) => {e.stopPropagation(); saveLastPath((row as DossierRecherche).id)}}
        >
          {companyRef}
        </Link>
      ),
    },
    {
      id: 'dateMissionnement',
      label: 'Date missionnement',
      render: (date: string) => DateUtils.APIStrToLocalDateString(date),
      renderForExport: (date: string) => DateUtils.APIStrToLocalDateString(date),
    },
    {
      id: 'ville',
      label: 'Ville',
    },
    {
      id: 'natureSinistre.libelle',
      label: 'Type de sinsitre',
    },
    {
      id: 'statutComposeDossier',
      label: 'Statut',
      sortId: 'statutComposeDossier.code',
      render: (status: CodeLabel) => {
        return (
          <StatusChipContainer>
            <ColoredSquareChip
              color={getCaseStatusColor(status.code)}
              className="status-chip"
            >
              {status.libelle}
            </ColoredSquareChip>
          </StatusChipContainer>
        )
      },
      renderForExport: (status: CodeLabel) => status.libelle || '',
    },
  ]

  const exportDataToXLSX = () => {
    const list = selectedCases.length > 0 ? selectedCases : caseList

    exportToXLSX(cols.filter((col) => !col.removeFromExport), list, `vos dossiers_${new Date().toISOString().replace(/[-T:]/g, '').slice(0, 14)}`)
  }

  const onStatusFilterClick = (status: string) => {
    if (formikForm.values.Statut?.includes(status)) {
      formikForm.setFieldValue('Statut', formikForm.values.Statut.filter((stat) => stat !== status))
    } else {
      formikForm.setFieldValue('Statut', [ ...formikForm.values.Statut ?? [], status ])
    }
  }

  const handleSort = (property: DataName) => () => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
    setCaseList(caseList.slice().sort(getRowsComparator(isAsc ? 'desc' : 'asc', property)))
    setOpenedMenu({ ...openedMenu, sort: false })
  }

  const handleTabChange = (newValue: number): void => {
    setTabValue(newValue)
  }

  return (
    <Form form={formikForm}>
      <TabsContainer
        value={tabValue === -1 ? false : tabValue}
        variant="scrollable"
      >
        {
          tabs.map((tab, index) => (
            <Tab
              key={`${tab.value}-${index}`}
              onClick={() => handleTabChange(index)}
              label={tab.label}
              color="info"
            />
          ))
        }
      </TabsContainer>
      <Card>
        <CardContent>
          <LargeTitle>
            Tous les dossiers
            <SearchExportContainer>
              <Field
                component={TextField}
                name="RechercheLibre"
                placeholder="Rechercher dans le tableau"
                size="small"
                InputProps={
                  {
                    endAdornment: (
                      <IconButton type="submit">
                        <Search />
                      </IconButton>
                    ),
                  }
                }
              />
              <LongButton
                variant="contained"
                onClick={exportDataToXLSX}
              >
                Exporter
              </LongButton>
            </SearchExportContainer>
          </LargeTitle>
          <FilterDesktopContainer>
            <CaseListFilters
              companyList={companyList}
              isFetchingCompanyList={isFetchingCompanyList}
              disasterNatureList={disasterNatureList}
              isFetchingDisasterNatureList={isFetchingDisasterNatureList}
              departmentList={departmentList}
              isFetchingDepartmentList={isFetchingDepartmentList}
              periodList={periodList}
            />
          </FilterDesktopContainer>
          <FilterMobileContainer>
            <BoldSeparator />
            <FormTitleWithArrow onClick={() => setOpenedMenu({ ...openedMenu, filter: !openedMenu.filter })}>
              Filtres
              <DropDownArrow open={openedMenu.filter} />
            </FormTitleWithArrow>
            <Collapse
              in={openedMenu.filter}
              timeout="auto"
              unmountOnExit
            >
              <CaseListFilters
                companyList={companyList}
                isFetchingCompanyList={isFetchingCompanyList}
                disasterNatureList={disasterNatureList}
                isFetchingDisasterNatureList={isFetchingDisasterNatureList}
                departmentList={departmentList}
                isFetchingDepartmentList={isFetchingDepartmentList}
                periodList={periodList}
              />
            </Collapse>
            <BoldSeparator />
            <FormTitleWithArrow onClick={() => setOpenedMenu({ ...openedMenu, sort: !openedMenu.sort })}>
              Trier par
              <DropDownArrow open={openedMenu.sort} />
            </FormTitleWithArrow>
            <Collapse
              in={openedMenu.sort}
              timeout="auto"
              unmountOnExit
            >
              {
                cols.map((col) => (
                  <CheckableSortingButton
                    key={col.id}
                    checked={orderBy === col.id}
                    label={col.label}
                    onChange={handleSort(col.sortId ?? col.id)}
                    order={order}
                  />
                ))
              }
            </Collapse>
            <BoldSeparator />
          </FilterMobileContainer>
          <FormBoldTitle>
            Filtrer par statut
          </FormBoldTitle>
          <ScrollableFiltersContainer>
            {
              statusFilters.map((filter) => (
                <CustomChip
                  variant={formikForm.values.Statut?.includes(filter.status.code) ? 'contained' : 'outlined'}
                  key={filter.status.code}
                  onClick={() => onStatusFilterClick(filter.status.code)}
                >
                  {filter.status.libelle}
                  <b>
                    {filter.amount}
                  </b>
                </CustomChip>
              ))
            }
          </ScrollableFiltersContainer>
        </CardContent>
      </Card>
      <LoadingOverlay isLoading={isFetchingCaseResultList}>
        <TableCardContainer>
          <TableCardContentContainer>
            <Table
              rows={caseList}
              setRows={(rows) => setCaseList(rows as DossierRecherche[])}
              colHeaders={cols}
              onRowClick={(row) => handleNavigate((row as DossierRecherche).id)}
              limit={RESULT_LIMIT}
              pagination={
                {
                  setServerLimit: (limit) => formikForm.setFieldValue('Limite', limit),
                  setServerStartIndex: (startIndex) => formikForm.setFieldValue('IndexDebut', startIndex),
                  totalRows,
                }
              }
              sorting={{ setOrder, setOrderBy }}
              selected={selectedCases}
              setSelected={(value) => setSelectedCases(value as DossierRecherche[])}
            />
          </TableCardContentContainer>
        </TableCardContainer>
        <MobileCardContainer>
          {
            caseList.map((myCase, index) => (
              <CaseListMobileCard
                key={`${myCase.id}-${index}`}
                myCase={myCase}
                handleNavigate={() => handleNavigate(myCase.id)}
              />
            ))
          }
        </MobileCardContainer>
      </LoadingOverlay>
      <Footer />
    </Form>
  )
}

export default CaseListPage
