/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import DateUtils from 'helpers/DateUtils'

/* Component imports -------------------------------------------------------- */
import ColoredSquareChip from 'components/ColoredSquareChip/ColoredSquareChip'
import type { WorkflowEtat } from 'API/__generated__/Api'
import { getJalonStatusColor } from 'helpers/caseUtils'

/* Styled components -------------------------------------------------------- */
interface ContainerProps {
  last: boolean;
}

const Container = styled.div<ContainerProps>`
  border-right: ${(props) => !props.last ? `2px solid ${props.theme.colors.grey}` : ''};
  padding: 10px 15px;
  color: ${(props) => props.theme.palette.secondary.main};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 150px;
  font-size: .9rem;
`

const Chip = styled(ColoredSquareChip)`
  flex-direction: column;
  width: 100%;
  margin-top: 5px;
  height: 100%;
`

/* Component declaration ---------------------------------------------------- */
interface CaseHistoryJalonProps {
  label: string;
  date?: string | null;
  state?: WorkflowEtat;
  last?: boolean;
}

const CaseHistoryJalon: React.FC<CaseHistoryJalonProps> = ({
  label,
  date,
  state,
  last = false,
}) => {
  return (
    <Container last={last}>
      <div>
        {label}
      </div>
      {
        state &&
          <Chip color={getJalonStatusColor(state)}>
            {state}
            {
              date &&
                <div>
                  {DateUtils.APIStrToLocalDateString(date)}
                </div>
            }
          </Chip>
      }
    </Container>
  )
}

export default CaseHistoryJalon
