/* Framework imports -------------------------------------------------------- */
import React from 'react'

/* Module imports ----------------------------------------------------------- */
import {
  Navigate,
  Route,
  Routes,
} from 'react-router-dom'
import { useAuthInfo } from 'store/hooks'
import { caseTabs } from 'helpers/caseUtils'

/* Component imports -------------------------------------------------------- */
// Layouts
import AuthLayout from 'layouts/AuthLayout/AuthLayout'
import MainLayout from 'layouts/MainLayout/MainLayout'
import CaseLayout from 'layouts/CaseLayout/CaseLayout'
// Auth Pages
import LoginPage from 'pages/AuthPages/LoginPage/LoginPage'
// Main Pages
import NotFoundPage from 'pages/NotFoundPage/NotFoundPage'
import CaseListPage from 'pages/CaseListPage/CaseListPage'
import PlanningPage from 'pages/PlanningPages/PlanningPage/PlanningPage'
import PlanningMapPage from 'pages/PlanningPages/PlanningMapPage/PlanningMapPage'

/* Component declaration ---------------------------------------------------- */
interface RouterProps {}

const Router: React.FC<RouterProps> = () => {
  const authInfo = useAuthInfo()

  console.log('[DEBUG] authInfo :', authInfo)

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
  $crisp.push([ 'set', 'user:nickname', [ `${window.location.host.replace('i-ren.fr', '')} - ${authInfo?.nom}` ] ])

  const getCaseTabs = () => {
    return caseTabs.map((tab) => (
      <Route
        key={tab.path}
        path={tab.path}
        element={<tab.component props={{}} />}
      />
    ))
  }

  return (
    <Routes>
      <Route
        path="/"
        element={
          authInfo !== null ?
            <MainLayout /> :
            <Navigate
              to="/connexion"
              replace
            />
        }
      >
        <Route path="dossiers">
          <Route
            index
            element={<CaseListPage />}
          />
          <Route
            path=":caseId"
            element={<CaseLayout />}
          >
            <Route
              index
              element={
                <Navigate
                  to={caseTabs[0].path}
                  replace
                />
              }
            />
            {getCaseTabs()}
          </Route>
        </Route>
        <Route
          index
          element={
            <Navigate
              to="/dossiers"
              replace
            />
          }
        />
        <Route
          path="/planning"
          element={<PlanningPage />}
        />
        <Route
          path="/planning/carte"
          element={<PlanningMapPage />}
        />
        <Route
          path="/planning/carte/:eventDate/:eventId"
          element={<PlanningMapPage />}
        />
        <Route
          path="*"
          element={<NotFoundPage />}
        />
      </Route>
      <Route
        element={
          authInfo === null ?
            <AuthLayout /> :
            <Navigate
              to="/"
              replace
            />
        }
      >
        <Route
          path="/connexion"
          element={<LoginPage />}
        />
      </Route>
      <Route
        path="*"
        element={<NotFoundPage />}
      />
    </Routes>
  )
}

export default Router
