/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useState,
} from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import DateUtils from 'helpers/DateUtils'

/* Component imports -------------------------------------------------------- */
import PlanningEventCard from 'pages/PlanningPages/PlanningComponents/PlanningEventCard'

/* Type imports ------------------------------------------------------------- */
import type { Planning } from 'types/Planning'

/* Type declarations -------------------------------------------------------- */
type EventGroup = {[x: string]: Planning[]}

/* Styled components -------------------------------------------------------- */
const EventListContainer = styled.div`
  margin-left: 1rem;
  margin-right: 1rem;
`

const EventDayListContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
`

const DateContainer = styled.div`
  color: ${(props) => props.theme.palette.secondary.main};
  font-weight: bold;
  font-size: 1rem;
  margin-bottom: 5px;
  text-transform: uppercase;
`

const HourContainer = styled.div`
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 1rem;
`

const EventCardContainer = styled.div`
  display: flex;
  gap: 20px;
  width: 100%;
  align-items: center;
`

const NumberContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 38px;
  font-weight: bold;
  background-color: ${(props) => props.theme.palette.primary.main};
  color: white;
  border-radius: 100%;
`

/* Component declaration ---------------------------------------------------- */
interface PlanningEventListProps {
  events: Planning[];
  selectedEvent?: string;
  setSelectedEvent?: (event: Planning, scrollToTop?: boolean) => void;
  periodType?: string;
  displayOrder?: boolean;
}

const PlanningEventList: React.FC<PlanningEventListProps> = ({
  events,
  selectedEvent,
  setSelectedEvent,
  periodType,
  displayOrder = false,
}) => {
  const [ groupedEvent, setGroupedEvents ] = useState<EventGroup>({})

  const getStartAndEndHours = (event: Planning): string => {
    const debutDate = new Date(event.dateDebut)
    const endDate = new Date(event.dateFin)

    return `${debutDate.getHours()}h${(debutDate.getMinutes().toString()).padStart(2, '0')} - ${endDate.getHours()}h${(endDate.getMinutes().toString()).padStart(2, '0')}`
  }

  useEffect(() => {
    const sortedEvents: Planning[] = [ ...events.sort((a, b) => a.dateDebut?.localeCompare(b.dateDebut)) ]
    const groups: EventGroup = {}

    const sortEventsToGroup = (date: string): void => {
      if (!sortedEvents.length) return
      if (!groups[date]?.length) groups[date] = []
      if (DateUtils.APIStrToLocalDateString(date) === DateUtils.APIStrToLocalDateString(sortedEvents[0]?.dateDebut)) {
        const toAdd = sortedEvents.shift()
        toAdd && groups[date].push(toAdd)
        sortEventsToGroup(date)
      }
      else {
        sortEventsToGroup(sortedEvents[0].dateDebut)
      }
    }

    sortEventsToGroup(sortedEvents[0]?.dateDebut)
    setGroupedEvents(groups)
  }, [ events ])

  return (
    <EventListContainer>
      {
        Object.keys(groupedEvent).map((key, keyIndex) => (
          <EventDayListContainer key={`${key}-${keyIndex}`}>
            {
              periodType !== 'Jour' && (
                <DateContainer>
                  {
                    DateUtils.APIStrToLocalDateString(key, {
                      weekday: 'long',
                      month: 'long',
                      day: 'numeric',
                      year: 'numeric',
                    })
                  }
                </DateContainer>
              )
            }
            {
              groupedEvent[key].map((event, eventIndex) => (
                <div key={`${event.id}-${eventIndex}`}>
                  <HourContainer>
                    {event.journeeComplete ? eventIndex === 0 ? 'Journée complète' : '' : getStartAndEndHours(event)}
                  </HourContainer>
                  <EventCardContainer>
                    {
                      displayOrder &&
                        <NumberContainer>
                          {eventIndex + 1}
                        </NumberContainer>
                    }
                    <PlanningEventCard
                      key={`${event.id}-${eventIndex}`}
                      event={event}
                      selectedEvent={selectedEvent}
                      setSelectedEvent={setSelectedEvent}
                    />
                  </EventCardContainer>
                </div>
              ))
            }
          </EventDayListContainer>
        ))
      }
    </EventListContainer>
  )
}

export default PlanningEventList
