/* Framework imports -------------------------------------------------------- */
import React, { useMemo } from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { useWindowSize } from 'helpers/hooks/useWindowSize'

/* Type imports ------------------------------------------------------------- */
import { WorkflowEtat } from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
interface JalonProps {
  state: 'done' | 'current' | 'none';
  height: number;
}

const ArrowSvg = styled.svg<JalonProps>`
  width: 100%;
  height: ${(props) => props.height}px;

  path {
    fill: ${(props) => props.state === 'done' ? props.theme.palette.secondary.main : props.state === 'current' ? props.theme.palette.primary.main : props.theme.colors.grey};
  }
`

/* Component declaration ---------------------------------------------------- */
interface CaseHistoryArrowProps {
  state: WorkflowEtat;
  id: string;
}

const CaseHistoryArrow: React.FC<CaseHistoryArrowProps> = ({ state, id }) => {
  const window = useWindowSize()
  const svg = useMemo(() => document.getElementById(id)?.clientWidth ?? 126, [ document, state, id, window ])
  const height = 8

  return (
    <ArrowSvg
      id={id}
      state={state === WorkflowEtat.Fait ? 'done' : state === WorkflowEtat.EnAttente ? 'current' : 'none'}
      viewBox={`0 0 ${svg - 1} ${height}`}
      height={height}
    >
      <path d={`M0 0H${svg - 4}L${svg} ${height / 2}L${svg - 4} ${height}H0L4 ${height / 2}L0 0Z`} />
    </ArrowSvg>
  )
}

export default CaseHistoryArrow
