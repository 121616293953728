/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useRef,
  useState,
} from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
import { useNavigate } from 'react-router-dom'
import DateUtils from 'helpers/DateUtils'
import {
  useAppDispatch,
  useAppSelector,
} from 'store/hooks'
import {
  getPlanningState,
  setPlanningStartDate,
  setPlanningViewType,
  setIsTimelineMode,
} from 'store/slices/planningSlice'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  Card,
  CardContent,
  Switch,
} from '@mui/material'
import PageContainer from 'layouts/PageContainer/PageContainer'
import SegmentedButtons from 'components/SegmentedButtons/SegmentedButtons'
import LargeTitle from 'components/LargeTitle/LargeTitle'
import PlanningCalendar from './PlanningCalendar/PlanningCalendar'
import PlanningEventHandlerModal from './PlanningEventHandlerModal/PlanningEventHandlerModal'
import PlanningCalendarButtons from '../PlanningComponents/PlanningCalendarButtons'
import PlanningFilters from '../PlanningComponents/PlanningFilters'

/* Type imports ------------------------------------------------------------- */
import type FullCalendar from '@fullcalendar/react'
import type { Planning } from 'types/Planning'
import {
  currentPlanningData,
  currentStakeholders,
  currentTypes,
} from 'types/Planning'
import type {
  EventHandlerActionType,
  EventHandlerType,
} from 'types/EventHandler'
import type { SegmentedButtonOption } from 'components/SegmentedButtons/SegmentedButtons'
import { CalendarViewTypes } from 'helpers/FullCalendarOptions'

/* Internal variables ------------------------------------------------------- */
const CalendarMapping: Record<string, string> = {
  listWeek: 'Liste',
  timeGridDay: 'Jour',
  timeGridWeek: 'Semaine',
  dayGridMonth: 'Mois',
}

const TimelineMapping: Record<string, string> = {
  resourceTimeline: 'Jour',
  resourceTimelineWeek: 'Semaine',
  resourceTimelineWeekBusy: 'Indisponibilité',
}

const sInitialCalendarView: string = 'dayGridMonth'

/* Styled components -------------------------------------------------------- */
const SegmentedButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto auto;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: -10px;
  color: ${(props) => props.theme.palette.secondary.main};

  .switch {
    font-size: 1.2rem;
    font-weight: bold;
  }

  @media ${(props) => props.theme.media.mobile.portrait} {
    display: flex;
    flex-direction: column;
  }
`

const FilterAndCalendar = styled.div`
  display: flex;
  gap: 15px;
  margin-top: 15px;
  height: calc(100vh - 230px);

  @media ${(props) => props.theme.media.desktop} {
    height: calc(100vh - 175px);
  }

  @media ${(props) => props.theme.media.mobile.main} {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
`

const CalendarContainer = styled.div`
  width: 100%;

  @media ${(props) => props.theme.media.mobile.main} {
    height: calc(100vh - 75px);
  }
`

const DateContainer = styled.div`
  display: flex;
  gap: 10px;
`

/* Component declaration ---------------------------------------------------- */
interface PlanningPageProps {}

const PlanningPage: React.FC<PlanningPageProps> = () => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const planningState = useAppSelector(getPlanningState)
  const calendarRef: React.RefObject<FullCalendar> = useRef<FullCalendar>(null)
  const [ events, setEvents ] = useState<Planning[]>([])
  const [ eventHandler, setEventHandler ] = useState<EventHandlerType>({ open: false, action: null, event: undefined, dates: null, stakeholderId: undefined })
  const [ rdvFilters, setRdvFilters ] = useState<string[]>([])
  const [ stakeholderFilters, setStakeholdersFilters ] = useState<string[]>([])
  const [ timelineMode, setTimelineMode ] = useState<boolean>(planningState.isTimelineMode || false)

  useEffect(() => {
    if (calendarRef.current !== null && planningState?.viewType) {
      calendarRef.current.getApi().changeView(planningState.viewType)
    }
  }, [ calendarRef ])

  useEffect(() => {
    setEvents(currentPlanningData)
  }, [])

  const updateCalendarView = (): void => {
    if (calendarRef.current !== null) {
      dispatch(setPlanningStartDate(calendarRef.current.getApi().view.currentStart.toISOString()))
    }
  }

  const changeCalendarView = (pViewType: string): void => {
    if (calendarRef.current !== null) {
      dispatch(setPlanningViewType(pViewType as CalendarViewTypes))
      calendarRef.current.getApi().changeView(pViewType)
      updateCalendarView()
    }
  }

  const updateTimeline = (value: boolean) => {
    setTimelineMode(value)
    dispatch(setIsTimelineMode(value))
    if (value) {
      if (planningState.viewType === CalendarViewTypes.Day || planningState.viewType === CalendarViewTypes.List) {
        changeCalendarView(CalendarViewTypes.HorizontalDay)
      }
      else if (planningState.viewType === CalendarViewTypes.Week || planningState.viewType === CalendarViewTypes.Month) {
        changeCalendarView(CalendarViewTypes.HorizontalWeek)
      }
    } else {
      if (planningState.viewType === CalendarViewTypes.HorizontalDay) {
        changeCalendarView(CalendarViewTypes.Day)
      } else {
        changeCalendarView(CalendarViewTypes.Week)
      }
    }
  }

  const onPreviousClicked: React.MouseEventHandler<HTMLButtonElement> = () => {
    if (calendarRef.current !== null) {
      calendarRef.current.getApi().prev()
      updateCalendarView()
    }
  }

  const onNextClicked: React.MouseEventHandler<HTMLButtonElement> = () => {
    if (calendarRef.current !== null) {
      calendarRef.current.getApi().next()
      updateCalendarView()
    }
  }

  const onTodayClicked: React.MouseEventHandler<HTMLButtonElement> = () => {
    if (calendarRef.current !== null) {
      calendarRef.current.getApi().today()
      updateCalendarView()
    }
  }

  const onDateClicked = (value: Date | null) => {
    if (calendarRef.current !== null && value !== null) {
      calendarRef.current.getApi().gotoDate(value)
      updateCalendarView()
    }
  }

  const onCalendarOptionClick = (pOption: string): void => {
    const lKey = Object.keys(CalendarMapping).find((pKey) => CalendarMapping[pKey] === pOption)

    if (lKey !== undefined) {
      changeCalendarView(lKey)
    }
  }

  const onTimelineOptionClick = (pOption: string): void => {
    const lKey = Object.keys(TimelineMapping).find((pKey) => TimelineMapping[pKey] === pOption)

    if (lKey !== undefined) {
      changeCalendarView(lKey)
    }
  }

  const onEventChange = (type?: EventHandlerActionType | null, newEvent?: Planning): void => {
    setEventHandler({ open: false, action: null, event: undefined, dates: null, stakeholderId: undefined })

    if (!newEvent || !newEvent.id) return
    // const data: PlanningActionGeneraleRequest = {
    //   collaborateur: '',
    //   dateDebut: DateUtils.dateStringToAPILocalTimeString(newEvent.dateDebut),
    //   dateFin: DateUtils.dateStringToAPILocalTimeString(newEvent.dateFin),
    //   typeRdv: newEvent.typeRDV.code,
    //   commentaire: newEvent.commentaire,
    //   journeeComplete: newEvent.journeeComplete ?? false,
    // }
    if (type === 'edit') {
      setEvents([ ...events.filter((e) => e.id !== newEvent.id), newEvent ])
    }
    if (type === 'add') {
      setEvents([ ...events, newEvent ])
    }
  }

  const filterEvents = (event: Planning): boolean => {
    if (calendarRef.current !== null) {
      const { activeStart, activeEnd } = calendarRef.current.getApi().view
      const startDate: Date = DateUtils.APIStrToDate(event.dateDebut)
      const endDate: Date = DateUtils.APIStrToDate(event.dateFin)

      return startDate >= activeStart && endDate <= activeEnd &&
      (!rdvFilters?.length || rdvFilters.includes(event.typeRDV?.id)) &&
      (!stakeholderFilters?.length || stakeholderFilters.some((filter) => event.intervenants?.find((stakeholder) => stakeholder.id === filter)))
    }
    return false
  }

  const filteredEvents: Planning[] = events.filter((e: Planning): boolean => filterEvents(e))

  const calendarOptions: SegmentedButtonOption<string>[] = Object.values(CalendarMapping).map((option) => ({ value: option }))
  const timelineModeOptions: SegmentedButtonOption<string>[] = Object.values(TimelineMapping).map((option) => ({ value: option }))

  return (
    <>
      {
        eventHandler.open && (
          <PlanningEventHandlerModal
            handleClose={onEventChange}
            types={[ ...currentTypes ].sort((a, b) => b.type.localeCompare(a.type))}
            {...eventHandler}
          />
        )
      }
      <PageContainer>
        <Card>
          <CardContent>
            <LargeTitle>
              Planning
              <DateContainer>
                <PlanningCalendarButtons
                  calendarViewTitle={calendarRef.current?.getApi()?.view.title || ''}
                  onPreviousClicked={onPreviousClicked}
                  onTodayClicked={onTodayClicked}
                  onNextClicked={onNextClicked}
                  dateToClick={calendarRef.current?.getApi().view.currentStart ?? null}
                  onDateClicked={onDateClicked}
                />
                <Button
                  onClick={() => setEventHandler({ open: true, action: 'add', event: undefined, dates: null, stakeholderId: undefined })}
                  variant="contained"
                >
                  Nouveau RDV
                </Button>
              </DateContainer>
            </LargeTitle>
            <SegmentedButtonsContainer>
              {
                timelineMode ?
                  <SegmentedButtons
                    options={timelineModeOptions}
                    selectedOption={TimelineMapping[calendarRef.current?.getApi().view.type || sInitialCalendarView]}
                    setSelectedOption={onTimelineOptionClick}
                    smaller
                  /> :
                  <SegmentedButtons
                    options={calendarOptions}
                    selectedOption={CalendarMapping[calendarRef.current?.getApi().view.type || sInitialCalendarView]}
                    setSelectedOption={onCalendarOptionClick}
                    smaller
                  />
              }
              <div className="switch">
                <Switch
                  checked={timelineMode}
                  onChange={() => updateTimeline(!timelineMode)}
                />
                Mode timeline
              </div>
              <Button
                variant="contained"
                onClick={() => navigate('/planning/carte')}
                fullWidth
              >
                Vue carte
              </Button>
            </SegmentedButtonsContainer>
          </CardContent>
        </Card>
        <FilterAndCalendar>
          <PlanningFilters
            rdvFilters={rdvFilters}
            setRdvFilters={setRdvFilters}
            stakeholderFilters={stakeholderFilters}
            setStakeholdersFilters={setStakeholdersFilters}
            rdvTypes={currentTypes}
            stakeholders={currentStakeholders}
          />
          <CalendarContainer>
            <PlanningCalendar
              calendarRef={calendarRef}
              initialView={planningState?.viewType ?? sInitialCalendarView}
              initialDate={planningState?.startDate ? new Date(planningState?.startDate) : new Date()}
              events={filteredEvents}
              stakeholders={!stakeholderFilters.length ? currentStakeholders : currentStakeholders.filter(((s) => stakeholderFilters.includes(s.id)))}
              setEventHandler={setEventHandler}
              onEventChange={onEventChange}
            />
          </CalendarContainer>
        </FilterAndCalendar>
      </PageContainer>
    </>
  )
}

export default PlanningPage
