import type {
  Adresse,
  CodeLabel,
} from 'API/__generated__/Api'

/* Type declarations -------------------------------------------------------- */
export interface LocalisationGPS {
  /** @format double */
  latitude?: number | null;
  /** @format double */
  longitude?: number | null;
}

export enum TypeRdvType {
  ActionGenerale = 'ActionGenerale',
  Dossier = 'Dossier',
  Inconnu = 'Inconnu',
}

export enum TypePersonne {
  Assure = 'Assure',
  Tiers = 'Tiers',
  Intervenant = 'Intervenant',
}

export interface Telephones {
  mobile: string;
  fixe: string;
}

export interface IntervenantPlanning {
  /** @minLength 1 */
  id: string;
  type: TypePersonne;
  /**
   * @minLength 1
   * @maxLength 32
   */
  nom: string;
  /** @maxLength 20 */
  prenom?: string | null;
  police?: string | null;
  qualite?: CodeLabel;
  /** @maxLength 20 */
  tel?: string | null;
  adresse?: Adresse;
  email?: string | null;
  color: string;
}

export interface Planning {
  id: string;
  typeRDV: TypeRdv;
  dateDebut: string;
  dateFin: string;
  dossierId?: string;
  adresse?: Adresse;
  commentaire?: string | null;
  commentaireAdresse?: string | null;
  assure?: string | null;
  telephones: Telephones;
  refMandant?: string | null;
  intervenants?: IntervenantPlanning[] | null;
  journeeComplete?: boolean;
}

export const currentQualities: CodeLabel[] = [
  {
    code: 'Peinture',
    libelle: 'Peinture',
  },
  {
    code: 'Electricite',
    libelle: 'Électricité',
  },
  {
    code: 'Maçonnerie',
    libelle: 'Maçonnerie',
  },
  {
    code: 'Plomberie',
    libelle: 'Plomberie',
  },
]

export const currentStakeholders: IntervenantPlanning[] = [
  {
    id: '1',
    nom: 'Toketchup',
    prenom: 'Thomas',
    type: TypePersonne.Intervenant,
    adresse: undefined,
    email: '',
    police: '',
    tel: undefined,
    qualite: currentQualities[0],
    color: '#9F91CC',
  },
  {
    id: '2',
    nom: 'Ochon',
    prenom: 'Paul',
    type: TypePersonne.Intervenant,
    adresse: undefined,
    email: '',
    police: '',
    tel: undefined,
    qualite: currentQualities[0],
    color: '#FF4B91',
  },
  {
    id: '3',
    nom: 'Houtan',
    prenom: 'Laurent',
    type: TypePersonne.Intervenant,
    adresse: undefined,
    email: '',
    police: '',
    tel: undefined,
    qualite: currentQualities[1],
    color: '#FF7676',
  },
  {
    id: '4',
    nom: 'Ptipeu',
    prenom: 'Justin',
    type: TypePersonne.Intervenant,
    adresse: undefined,
    email: '',
    police: '',
    tel: undefined,
    qualite: currentQualities[1],
    color: '#FFCD4B',
  },
  {
    id: '5',
    nom: 'Bonbeur',
    prenom: 'Jean',
    type: TypePersonne.Intervenant,
    adresse: undefined,
    email: '',
    police: '',
    tel: undefined,
    qualite: currentQualities[1],
    color: '#DCFFB7',
  },
  {
    id: '6',
    nom: 'Térieur',
    prenom: 'Alex',
    type: TypePersonne.Intervenant,
    adresse: undefined,
    email: '',
    police: '',
    tel: undefined,
    qualite: currentQualities[2],
    color: '#00A19D',
  },
  {
    id: '7',
    nom: 'Gonzola',
    prenom: 'Igor',
    type: TypePersonne.Intervenant,
    adresse: undefined,
    email: '',
    police: '',
    tel: undefined,
    qualite: currentQualities[3],
    color: '#45FFCA',
  },
  {
    id: '8',
    nom: 'Denledo',
    prenom: 'Geoffroy',
    type: TypePersonne.Intervenant,
    adresse: undefined,
    email: '',
    police: '',
    tel: undefined,
    qualite: currentQualities[3],
    color: '#79E0EE',
  },
]

export const currentPlanningData: Planning[] = [
  {
    'id': 'O¤CO¤BANSARD 01¤24-04-2024 11:00¤24-04-2024 13:00¤1000',
    'dateDebut': '2024-05-24T11:00:00',
    'dateFin': '2024-05-24T13:00:00',
    dossierId: '2024-SY-00000020',
    'adresse': { adresse1: '2 Rue Paul Camelle', codePostal: '33100', ville: 'Bordeaux' },
    'assure': undefined,
    'telephones': { fixe: '', mobile: '' },
    'commentaire': 'Lyon',
    'typeRDV': {
      id: '1',
      libelle: 'Rdv Métré',
      color: '#FCEAD1',
      type: TypeRdvType.Dossier,
    },
    'journeeComplete': false,
    commentaireAdresse: '',
    intervenants: [ currentStakeholders[0], currentStakeholders[1] ],
    refMandant: '',
  },
  {
    'id': 'O¤CO¤BANSARD 01¤24-04-2024 12:30¤24-04-2024 13:30¤1000',
    'dateDebut': '2024-05-24T12:30:00',
    'dateFin': '2024-05-24T13:30:00',
    dossierId: '2024-SY-00000020',
    'adresse': { adresse1: '86 Cours du Maréchal Juin', codePostal: '33800', ville: 'Bordeaux' },
    'assure': undefined,
    'telephones': { fixe: '', mobile: '' },
    'commentaire': 'Test',
    'typeRDV': {
      id: '2',
      libelle: 'Rdv Travaux',
      color: '#D3F8FC',
      type: TypeRdvType.Dossier,
    },
    'journeeComplete': false,
    commentaireAdresse: '',
    intervenants: [ currentStakeholders[2], currentStakeholders[3] ],
    refMandant: '',
  },
  {
    'id': 'O¤CO¤BANSARD 01¤24-04-2024 15:30¤24-04-2024 16:00¤1000',
    'dateDebut': '2024-05-24T15:30:00',
    'dateFin': '2024-05-24T16:00:00',
    dossierId: '2024-SY-00000020',
    'adresse': { adresse1: '36 place des Capucins', codePostal: '33800', ville: 'Bordeaux' },
    'assure': undefined,
    'telephones': { fixe: '', mobile: '' },
    'commentaire': '[Cypress#qbkzz]',
    'typeRDV': {
      id: '3',
      libelle: 'Rdv Dossier',
      color: '#FCE1D5',
      type: TypeRdvType.Dossier,
    },
    'journeeComplete': false,
    commentaireAdresse: '',
    intervenants: [ currentStakeholders[0], currentStakeholders[1] ],
    refMandant: '',
  },
  {
    'id': 'O¤CO¤BANSARD 01¤25-04-2024 10:00¤25-04-2024 15:00¤1000',
    'dateDebut': '2024-05-23T10:00:00',
    'dateFin': '2024-05-23T15:00:00',
    dossierId: '2024-SY-00000020',
    'adresse': { adresse1: '8 rue des mots passants', codePostal: '33800', ville: 'Bordeaux' },
    'assure': undefined,
    'telephones': { fixe: '', mobile: '' },
    'commentaire': 'Bur',
    'typeRDV': {
      id: '4',
      libelle: 'Rdv Urgent',
      color: '#FFC6AD',
      type: TypeRdvType.Dossier,
    },
    'journeeComplete': false,
    commentaireAdresse: '',
    intervenants: [ currentStakeholders[2], currentStakeholders[4], currentStakeholders[7] ],
    refMandant: '',
  },
  {
    'id': 'O¤CO¤BANSARD 01¤25-04-2024 12:00¤25-04-2024 14:00¤1000',
    'dateDebut': '2024-05-23T12:00:00',
    'dateFin': '2024-05-23T14:00:00',
    dossierId: '2024-SY-00000020',
    'adresse': { adresse1: '8 rue des mots passants', codePostal: '33800', ville: 'Bordeaux' },
    'assure': undefined,
    'telephones': { fixe: '', mobile: '' },
    'commentaire': 'Nan',
    'typeRDV': {
      id: '5',
      libelle: 'Indisponibilité',
      color: '#ffbdc7',
      type: TypeRdvType.ActionGenerale,
    },
    'journeeComplete': false,
    commentaireAdresse: '',
    intervenants: [ currentStakeholders[5], currentStakeholders[6], currentStakeholders[7] ],
    refMandant: '',
  },
  {
    'id': 'O¤CO¤BANSARD 01¤26-04-2024 11:00¤26-04-2024 12:00¤1000',
    'dateDebut': '2024-05-22T11:00:00',
    'dateFin': '2024-05-22T12:00:00',
    dossierId: '2024-SY-00000020',
    'adresse': { adresse1: '18 rue du relais', codePostal: '33318', ville: 'Pessac' },
    'assure': undefined,
    'telephones': { fixe: '', mobile: '' },
    'commentaire': 'pk pas',
    'typeRDV': {
      id: '1',
      libelle: 'Rdv Métré',
      color: '#FCEAD1',
      type: TypeRdvType.Dossier,
    },
    'journeeComplete': false,
    commentaireAdresse: '',
    intervenants: [ currentStakeholders[3] ],
    refMandant: '',
  },
  {
    'id': 'O¤CO¤BANSARD 01¤27-04-2024 00:00¤28-04-2024 00:00¤1000',
    'dateDebut': '2024-05-27T00:00:00',
    'dateFin': '2024-05-28T00:00:00',
    dossierId: '2024-SY-00000020',
    'adresse': { adresse1: '18 rue du relais', codePostal: '33318', ville: 'Pessac' },
    'assure': undefined,
    'telephones': { fixe: '', mobile: '' },
    'commentaire': 'Vac',
    'typeRDV': {
      id: '2',
      libelle: 'Rdv Travaux',
      color: '#D3F8FC',
      type: TypeRdvType.Dossier,
    },
    'journeeComplete': true,
    commentaireAdresse: '',
    intervenants: [ currentStakeholders[3], currentStakeholders[4] ],
    refMandant: '',
  },
  {
    'id': 'O¤CO¤BANSARD 01¤09-07-2024 00:00¤10-07-2024 00:00¤1000',
    'dateDebut': '2024-07-09T00:00:00',
    'dateFin': '2024-07-10T00:00:00',
    dossierId: '2024-SY-00000020',
    'adresse': { adresse1: '36 place des Capucins', codePostal: '33800', ville: 'Bordeaux' },
    'assure': undefined,
    'telephones': { fixe: '', mobile: '' },
    'commentaire': 'Test1',
    'typeRDV': {
      id: '3',
      libelle: 'Rdv Dossier',
      color: '#FCE1D5',
      type: TypeRdvType.Dossier,
    },
    'journeeComplete': false,
    commentaireAdresse: '',
    intervenants: [ currentStakeholders[4], currentStakeholders[5] ],
    refMandant: '',
  },
]

export interface TypeRdv {
  id: string;
  libelle: string;
  color: string;
  type: TypeRdvType;
}

export const currentTypes: TypeRdv[] = [
  {
    id: '1',
    libelle: 'Rdv Métré',
    color: '#FCEAD1',
    type: TypeRdvType.Dossier,
  },
  {
    id: '2',
    libelle: 'Rdv Travaux',
    color: '#D3F8FC',
    type: TypeRdvType.Dossier,
  },
  {
    id: '3',
    libelle: 'Rdv Dossier',
    color: '#FCE1D5',
    type: TypeRdvType.Dossier,
  },
  {
    id: '4',
    libelle: 'Rdv Urgent',
    color: '#FFC6AD',
    type: TypeRdvType.Dossier,
  },
  {
    id: '5',
    libelle: 'Indisponibilité',
    color: '#ffbdc7',
    type: TypeRdvType.ActionGenerale,
  },
]
