/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Type imports ------------------------------------------------------------- */
import type { EventContentArg } from '@fullcalendar/core'
import type { Planning } from 'types/Planning'

/* Styled components -------------------------------------------------------- */
interface ColoredBarProps {
  color: string;
}

const MonthBar = styled.div<ColoredBarProps>`
  background-color: ${(props) => props.color};
  border: ${(props) => props.color === '#FFFFFF' ? '1px solid #C0C0C0' : 'none'};
  width: 100%;
  height: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
`

const StakeholderBar = styled.div<ColoredBarProps>`
  background-color: ${(props) => props.color};
  width: 10px;
  height: 10px;
  border: 1px solid #00000019;
  border-radius: 2px;
`

/* Component declaration ---------------------------------------------------- */
interface PlanningCalendarEventMonthViewProps {
  eventInfo: EventContentArg;
  event?: Planning;
}

const PlanningCalendarEventMonthView: React.FC<PlanningCalendarEventMonthViewProps> = ({
  eventInfo,
  event,
}) => {

  return (
    <MonthBar
      id={event ? `${event.commentaire}#${event.typeRDV?.libelle}` : `${eventInfo.event.id}`}
      color={eventInfo.event.backgroundColor}
    >
      {
        event?.intervenants?.map((stakeholder, index) => (
          <StakeholderBar
            key={`${stakeholder.id}-${index}`}
            color={stakeholder.color}
          />
        ))
      }
    </MonthBar>
  )
}

export default PlanningCalendarEventMonthView
