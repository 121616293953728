/* Framework imports -------------------------------------------------------- */
import React, {
  // useMemo,
  useState,
} from 'react'
import styled from '@emotion/styled'

/* Module imports ----------------------------------------------------------- */
// import { useLocation } from 'react-router-dom'

/* Component imports -------------------------------------------------------- */
import {
  Card,
  Collapse,
} from '@mui/material'
import {
  FilterList,
  FilterListOff,
} from '@mui/icons-material'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import CheckboxButton from 'components/CheckboxButton/CheckboxButton'
import DropdownArrow from 'components/DropdownArrow/DropdownArrow'

/* Type imports ------------------------------------------------------------- */
import type {
  IntervenantPlanning,
  TypeRdv,
} from 'types/Planning'

/* Styled components -------------------------------------------------------- */
const FilterCard = styled(Card)`
  width: 265px;
  flex-shrink: 0;
  overflow-y: scroll;
  padding: 10px 5px 10px 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;

  @media ${(props) => props.theme.media.mobile.main} {
    width: 100%;
    gap: 10px;
  }

  @media ${(props) => props.theme.media.mobile.landscape} {
    justify-content: space-between;
    gap: 0px;
    flex-direction: row;
  }
`

const MiniCheckable = styled(CheckboxButton)`
  min-height: 40px;
  height: 40px;
  margin-bottom: 5px;
  color: ${(props) => props.theme.palette.secondary.main};
`

const TypeLabel = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`

const Square = styled.div`
  background-color: ${(props) => props.color};
  width: 25px;
  height: 25px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: .9rem;
`

const FullName = styled.div`
  max-width: 140px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

interface BoldTitleProps {
  isDropdown?: boolean;
}

const BoldTitle = styled(FormBoldTitle)<BoldTitleProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0px;

  @media ${(props) => props.theme.media.desktop} {
    cursor: ${(props) => props.isDropdown ? 'pointer' : 'initial'};
  }
`

const FilterIconContainer = styled.div`
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
`

/* Component declaration ---------------------------------------------------- */
interface PlanningFiltersProps {
  rdvFilters: string[];
  setRdvFilters: (value: string[]) => void;
  stakeholderFilters: string[];
  setStakeholdersFilters: (value: string[]) => void;
  rdvTypes: TypeRdv[];
  stakeholders: IntervenantPlanning[];
}

const PlanningFilters: React.FC<PlanningFiltersProps> = ({
  rdvFilters,
  setRdvFilters,
  stakeholderFilters,
  setStakeholdersFilters,
  rdvTypes,
  stakeholders,
}) => {
  // const location = useLocation()
  const [ expanded, setExpanded ] = useState({ place: true, stakeholders: true, rdv: true })

  const onRemoveFiltersClick = (filter: 'stakeholders' | 'rdv') => (event: React.MouseEvent<SVGElement>) => {
    event.stopPropagation()
    if (filter === 'rdv') {
      if (rdvFilters.length) {
        setRdvFilters([])
      } else {
        setRdvFilters(rdvTypes.map((curr) => curr.id))
      }
    }
    if (filter === 'stakeholders') {
      if (stakeholderFilters.length) {
        setStakeholdersFilters([])
      } else {
        setStakeholdersFilters(stakeholders.map((curr) => curr.id))
      }
    }
  }

  // const isMapPage = useMemo(() => location.pathname.indexOf('carte') !== -1, [ location ])

  return (
    <FilterCard>
      {
        // !isMapPage &&
        //   <div className="filters">
        //     <BoldTitle
        //       onClick={() => setExpanded({ ...expanded, place: !expanded.place })}
        //       isDropdown
        //     >
        //       Localisation
        //       <DropdownArrow expanded={expanded.place.toString()} />
        //     </BoldTitle>
        //     <Collapse
        //       in={expanded.place}
        //       timeout="auto"
        //       unmountOnExit
        //     >
        //       {/* <div>
        //         Département / Ville
        //       </div> */}
        //     </Collapse>
        //   </div>
      }
      <div>
        <BoldTitle
          onClick={() => setExpanded({ ...expanded, stakeholders: !expanded.stakeholders })}
          isDropdown
          className="filters"
        >
          Intervenants
          <FilterIconContainer>
            {
              stakeholderFilters.length ?
                <FilterListOff onClick={onRemoveFiltersClick('stakeholders')} /> :
                <FilterList onClick={onRemoveFiltersClick('stakeholders')} />
            }
            <DropdownArrow expanded={expanded.stakeholders.toString()} />
          </FilterIconContainer>
        </BoldTitle>
        <Collapse
          in={expanded.stakeholders}
          timeout="auto"
          unmountOnExit
        >
          {
            stakeholders.map((value) => (
              <MiniCheckable
                key={value.id}
                label={
                  <TypeLabel>
                    <FullName>
                      {`${value.prenom || ''} ${value.nom}`}
                    </FullName>
                    <Square color={value.color}>
                      {`${value.prenom?.[0] || ''}${value.nom[0]}`}
                    </Square>
                  </TypeLabel>
                }
                checked={stakeholderFilters.includes(value.id)}
                onChange={() => stakeholderFilters.includes(value.id) ? setStakeholdersFilters(stakeholderFilters.filter((v) => v !== value.id)) : setStakeholdersFilters([ ...stakeholderFilters, value.id ])}
              />
            ))
          }
        </Collapse>
      </div>
      <div>
        <BoldTitle
          onClick={() => setExpanded({ ...expanded, rdv: !expanded.rdv })}
          isDropdown
        >
          Type de rendez-vous
          <FilterIconContainer>
            {
              rdvFilters.length ?
                <FilterListOff onClick={onRemoveFiltersClick('rdv')} /> :
                <FilterList onClick={onRemoveFiltersClick('rdv')} />
            }
            <DropdownArrow expanded={expanded.rdv.toString()} />
          </FilterIconContainer>
        </BoldTitle>
        <Collapse
          in={expanded.rdv}
          timeout="auto"
          unmountOnExit
        >
          {
            rdvTypes.map((value) => (
              <MiniCheckable
                key={value.id}
                label={
                  <TypeLabel>
                    {value.libelle}
                    <Square color={value.color} />
                  </TypeLabel>
                }
                checked={rdvFilters.includes(value.id)}
                onChange={() => rdvFilters.includes(value.id) ? setRdvFilters(rdvFilters.filter((v) => v !== value.id)) : setRdvFilters([ ...rdvFilters, value.id ])}
              />
            ))
          }
        </Collapse>
      </div>
    </FilterCard>
  )
}

export default PlanningFilters
