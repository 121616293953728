/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Component imports -------------------------------------------------------- */
import {
  Card,
  CardContent,
} from '@mui/material'

/* Type imports ------------------------------------------------------------- */
import type { PiedFacture } from 'API/__generated__/Api'

/* Styled components -------------------------------------------------------- */
const TotalContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

const FirstTotalGrid = styled.div`
  display: grid;
  grid-template-columns: .5fr repeat(3, 1fr);
  gap: 20px;
  padding: 5px 15px;
  justify-items: end;
`

const FirstTotalContainer = styled(FirstTotalGrid)`
  font-weight: bold;
  text-transform: uppercase;
  border-bottom: 2px solid ${(props) => props.theme.colors.grey};
  justify-items: start;
`

const SencondTotalLine = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10vw;
`

/* Component declaration ---------------------------------------------------- */
interface QuoteTotalProps {
  calculs?: PiedFacture;
}

const QuoteTotal: React.FC<QuoteTotalProps> = ({ calculs }) => {

  const displayToFixed = (value?: number | null) => {
    if (!value) return '0.00 €'

    return (`${value.toFixed(2)} €`)
  }

  return (
    <TotalContainer>
      <Card>
        <FirstTotalContainer>
          <div>
            TVA
          </div>
          <div>
            Montant HT
          </div>
          <div>
            Montant TVA
          </div>
          <div>
            Montant TTC
          </div>
        </FirstTotalContainer>
        {
          calculs?.lignesTVAPiedFacture?.map((tvaline, index) => (
            <FirstTotalGrid key={`${tvaline.codeTVA}-${index}`}>
              <div>
                {tvaline.codeTVA}
              </div>
              <div>
                {displayToFixed(tvaline.montantHT)}
              </div>
              <div>
                {displayToFixed(tvaline.montantTVA)}
              </div>
              <div>
                {displayToFixed(tvaline.montantTTC)}
              </div>
            </FirstTotalGrid>
          ))
        }
      </Card>
      <Card>
        <CardContent>
          <SencondTotalLine>
            Montant HT
            <b>
              {displayToFixed(calculs?.montantHT)}
            </b>
          </SencondTotalLine>
          <SencondTotalLine>
            Montant TVA
            <b>
              {displayToFixed(calculs?.montantTVA)}
            </b>
          </SencondTotalLine>
          <SencondTotalLine>
            Montant TTC
            <b>
              {displayToFixed(calculs?.montantTTC)}
            </b>
          </SencondTotalLine>
          <SencondTotalLine>
            Franchise
            <b>
              {displayToFixed(calculs?.montantFranchise)}
            </b>
          </SencondTotalLine>
          <SencondTotalLine>
            Montant Net à payer
            <b>
              {displayToFixed(calculs?.montantNetAPayer)}
            </b>
          </SencondTotalLine>
        </CardContent>
      </Card>
    </TotalContainer>
  )
}

export default QuoteTotal
