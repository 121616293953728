/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Component imports -------------------------------------------------------- */
import Button from '@mui/material/Button'
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded'

/* Type imports ------------------------------------------------------------- */
import type { ButtonProps } from '@mui/material/Button'

/* Styled components -------------------------------------------------------- */
const BackButtonStyled = styled(Button)`
  font-size: 1rem;
  margin-bottom: 10px;
`

/* Component declaration ---------------------------------------------------- */
const BackButton: React.FC<ButtonProps> = (buttonProps) => {
  return (
    <BackButtonStyled
      {...buttonProps}
      variant="text"
      startIcon={<ArrowBackIosNewRoundedIcon />}
    >
      {buttonProps.children}
    </BackButtonStyled>
  )
}

export default BackButton
