/* Framework imports -------------------------------------------------------- */
import { createSlice } from '@reduxjs/toolkit'

/* Type imports ------------------------------------------------------------- */
import type { PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from 'store/store'
import { CalendarViewTypes } from 'helpers/FullCalendarOptions'

/* Type declarations -------------------------------------------------------- */
export interface PlanningState {
  viewType: CalendarViewTypes;
  startDate: string | null;
  isTimelineMode: boolean;
}

/* Redux slice -------------------------------------------------------------- */
const planningSlice = createSlice(
  {
    name: 'planning',
    initialState: {
      viewType: CalendarViewTypes.Month,
      startDate: null,
      isTimelineMode: false,
    } as PlanningState,
    reducers: {
      setPlanningViewType: (state, { payload }: PayloadAction<CalendarViewTypes>) => {
        state.viewType = payload
      },
      setPlanningStartDate: (state, { payload }: PayloadAction<string>) => {
        state.startDate = payload
      },
      setIsTimelineMode: (state, { payload }: PayloadAction<boolean>) => {
        state.isTimelineMode = payload
      },
    },
  },
)

/* Export slice components -------------------------------------------------- */
export const {
  setPlanningViewType,
  setPlanningStartDate,
  setIsTimelineMode,
} = planningSlice.actions

export default planningSlice.reducer

export const getPlanningState = (state: RootState): PlanningState => {
  return state.planning
}
