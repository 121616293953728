/* Framework imports -------------------------------------------------------- */
import { createSlice } from '@reduxjs/toolkit'

/* Module imports ----------------------------------------------------------- */
import type { RootState } from 'store/store'

/* Type imports ------------------------------------------------------------- */
import type { PayloadAction } from '@reduxjs/toolkit'

/* Type declarations -------------------------------------------------------- */
export interface CaseList {
  caseId: string;
  tab: string;
  name: string;
}

export interface CaseListState {
  list: CaseList[];
}

/* Redux slice -------------------------------------------------------------- */
const caseListSlice = createSlice(
  {
    name: 'caseList',
    initialState: {
      list: [],
    } as CaseListState,
    reducers: {
      addCase: (state, { payload }: PayloadAction<{caseId: string; tab: string; name: string}>) => {
        if (state.list.find((l) => l.caseId === payload.caseId)) {
          state.list[state.list.findIndex((l) => l.caseId === payload.caseId)].tab = payload.tab
        } else {
          state.list.push(payload)
        }
      },
      removeCase: (state, { payload }: PayloadAction<string>) => {
        return { list: [ ...state.list.filter((list) => list.caseId !== payload) ]}
      },
    },
  },
)

/* Export slice components -------------------------------------------------- */
export const { addCase, removeCase } = caseListSlice.actions

export default caseListSlice.reducer

export const getCaseList = (state: RootState): CaseList[] => {
  return state.caseList.list
}
