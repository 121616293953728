/* Framework imports -------------------------------------------------------- */
import React, { useMemo } from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import {
  useGetMessagesRecipientListQuery,
  usePostNewMessageMutation,
} from 'store/api'
import { useIsMobileLandscape } from 'helpers/hooks/useIsMedia'
import { isApiError } from 'helpers/fetchHelpers'

/* Component imports -------------------------------------------------------- */
import { Field } from 'formik'
import { TextField } from 'formik-mui'
import { toast } from 'react-toastify'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import LongButton from 'components/LongButton/LongButton'
import LoadingOverlay from 'components/Loader/LoadingOverlay'
import RoundedChip from 'components/RoundedChip/RoundedChip'

/* Type imports ------------------------------------------------------------- */
import type { FormikHelpers } from 'formik'
import type { CodeLabel } from 'API/__generated__/Api'

/* Type declarations -------------------------------------------------------- */
const messageSchema = Yup.object({
  destinataires: Yup.array(Yup.string().required('Il faut au moins un destinataire')).min(1, 'Il faut au moins un destinataire').required('Il faut au moins un destinataire'),
  message: Yup.string().required('Le message est obligatoire'),
})

type MessageRequest = Yup.InferType<typeof messageSchema>

/* Styled components -------------------------------------------------------- */
const ButtonLine = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 20px;
  gap: 20px;

  @media ${(props) => props.theme.media.mobile.portrait} {
    gap: 0px;
    justify-content: space-between;
  }

  @media ${(props) => props.theme.media.mobile.landscape} {
    margin-top: 10px;
  }
`

const RecipientObjectContainer = styled.div`
  @media ${(props) => props.theme.media.mobile.landscape} {
    display: grid;
    grid-template-columns: 48% 48%;
    gap: 4%;
    margin-bottom: -15px;

    .new-message-input {
      margin-top: 5px;
      margin-bottom: 5px;
    }
  }
`

const CustomTextField = styled(TextField)`
  @media ${(props) => props.theme.media.mobile.landscape} {
    margin-top: -5px;
  }
`

const RecipientsContainer = styled.div`
  button {
    margin: 2px;
  }
`

/* Component declaration ---------------------------------------------------- */
interface MessagesNewMessageProps {
  caseId: string;
  setExpanded: (value: boolean) => void;
}

const MessagesNewMessage: React.FC<MessagesNewMessageProps> = ({
  caseId,
  setExpanded,
}) => {
  const isMobileLandscape = useIsMobileLandscape()

  const {
    currentData: recipients = [],
    isFetching: isFetchingRecipients,
  } = useGetMessagesRecipientListQuery({ dossier: caseId })

  const [
    submitNewMessage,
  ] = usePostNewMessageMutation()

  const onSubmit = async (values: MessageRequest, { setSubmitting, resetForm }: FormikHelpers<MessageRequest>): Promise<void> => {
    const response = await submitNewMessage({
      caseId,
      data: {
        ...values,
        objet: '',
      },
    })

    if (!isApiError(response)) {
      resetForm()
      setExpanded(false)
    } else {
      toast.error("Erreur lors de l'envoi du message.")
      setSubmitting(false)
    }
  }

  const formikForm = useForm<MessageRequest>(
    {
      initialValues: {
        destinataires: [],
        message: '',
      },
      onSubmit: onSubmit,
      validationSchema: messageSchema,
    },
  )

  const recipientList: CodeLabel[] = useMemo((): CodeLabel[] => [ ...recipients ].sort((a: CodeLabel, b: CodeLabel): number => (a.libelle || '').localeCompare(b.libelle || '')), [ recipients ])

  const resetForm = () => {
    formikForm.resetForm()
    setExpanded(false)
  }

  const handleRecipientClick = (value: string) => () => {
    if (formikForm.values.destinataires.includes(value)) {
      formikForm.setFieldValue('destinataires', [ ...formikForm.values.destinataires.filter((li) => li === value) ])
    } else {
      formikForm.setFieldValue('destinataires', [ ...formikForm.values.destinataires, value ])
    }
  }

  return (
    <LoadingOverlay isLoading={isFetchingRecipients || formikForm.isSubmitting}>
      <Form form={formikForm}>
        <RecipientObjectContainer>
          <div>
            <FormBoldTitle
              className="new-message-input"
              required
            >
              Destinataire(s)
            </FormBoldTitle>
            <RecipientsContainer>
              {
                recipientList.map((value, index) => (
                  <RoundedChip
                    key={`${value.code}-${index}`}
                    variant={formikForm.values.destinataires.includes(value.code) ? 'contained' : 'outlined'}
                    onClick={handleRecipientClick(value.code)}
                  >
                    {value.libelle}
                  </RoundedChip>
                ))
              }
            </RecipientsContainer>
          </div>
        </RecipientObjectContainer>
        <FormBoldTitle required>
          Message
        </FormBoldTitle>
        <Field
          component={CustomTextField}
          placeholder="Votre message"
          rows={isMobileLandscape ? 2 : 3}
          multiline
          name="message"
          size="small"
        />
        <ButtonLine>
          <LongButton
            variant="outlined"
            onClick={resetForm}
            size={isMobileLandscape ? 'small' : 'medium'}
          >
            Annuler
          </LongButton>
          <LongButton
            type="submit"
            variant="contained"
            size={isMobileLandscape ? 'small' : 'medium'}
          >
            Envoyer
          </LongButton>
        </ButtonLine>
      </Form>
    </LoadingOverlay>
  )
}

export default MessagesNewMessage
