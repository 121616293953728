/* Framework imports -------------------------------------------------------- */
import React, {
  useEffect,
  useState,
} from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import {
  useNavigate,
  useParams,
} from 'react-router-dom'
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import { getRowsComparator } from 'helpers/tableUtils'

/* Component imports -------------------------------------------------------- */
import {
  Button,
  Collapse,
} from '@mui/material'
import { KeyboardArrowDownRounded } from '@mui/icons-material'
import Footer from 'layouts/Footer/Footer'
import LargeTitle from 'components/LargeTitle/LargeTitle'
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import CaseQuoteFilters from './CaseQuoteComponents/CaseQuoteFilters'
import CaseQuoteTable from './CaseQuoteComponents/CaseQuoteTable'

/* Type imports ------------------------------------------------------------- */
import type { FormikContextType } from 'formik'
import type {
  DataName,
  Order,
} from 'types/Table'
import type { PieceJointe } from 'API/__generated__/Api'

/* Types declaration -------------------------------------------------------- */
const quoteSchema = Yup.object({
  filters: Yup.object({
    types: Yup.array(Yup.string()),
    states: Yup.array(Yup.string()),
    recipients: Yup.array(Yup.string()),
  }).required(),
  sort: Yup.object({
    order: Yup.mixed<Order>().required(),
    orderBy: Yup.mixed<DataName>().required(),
  }).required(),
}).required()

type QuoteSchema = Yup.InferType<typeof quoteSchema>

type QuoteForm = FormikContextType<QuoteSchema>

/* Styled components -------------------------------------------------------- */
const FilterDesktopContainer = styled.div`
  display: grid;
  grid-template-columns: 75% 24%;
  justify-content: space-between;
  align-items: flex-end;

  @media ${(props) => props.theme.media.mobile.main} {
    display: none;
  }

  @media ${(props) => props.theme.media.tablet} {
    grid-template-columns: 1fr;
    gap: 10px;
  }
`

const FilterMobileContainer = styled.div`
  display: none;

  @media ${(props) => props.theme.media.mobile.main} {
    display: initial;
  }
`

const FormTitleWithArrow = styled(FormBoldTitle)`
  align-items: center;
  cursor: pointer;
  margin-bottom: 0px;
  margin-top: 10px;
`

interface DropDownArrowProps {
  open: boolean;
}

const DropDownArrow = styled(KeyboardArrowDownRounded)<DropDownArrowProps>`
  transform: scaleY(${(props) => props.open ? '-1' : '1'});
  color: ${(props) => props.theme.palette.secondary.main};
  font-size: 36px;
`

const BoldSeparator = styled.div`
  margin-top: 10px;
  border-bottom: 2px solid ${(props) => props.theme.colors.grey};
`

const BoldSeparatorMargin = styled(BoldSeparator)`
  margin-bottom: 10px;
`

/* Component declaration ---------------------------------------------------- */
interface CaseQuotePageProps {}

const CaseQuotePage: React.FC<CaseQuotePageProps> = () => {
  const navigate = useNavigate()
  const { caseId = '' } = useParams<{ caseId: string }>()
  const [ filteredList, setFilteredList ] = useState<PieceJointe[]>([])
  const [ openedFilterMenu, setOpenedFilterMenu ] = useState<boolean>(false)

  const formikForm: QuoteForm = useForm<QuoteSchema>(
    {
      initialValues: {
        filters: {
          types: [],
          states: [],
          recipients: [],
        },
        sort: {
          order: 'desc',
          orderBy: 'date',
        },
      },
      validationSchema: quoteSchema,
    },
  )

  useEffect(() => {
    const { types = [], states = [], recipients = []} = formikForm.values.filters
    const { order, orderBy } = formikForm.values.sort

    // set list
    setFilteredList([ ].sort(getRowsComparator(order, orderBy)).filter((value) =>
      (types.length === 0 || types.includes(value)) &&
        (states.length === 0 || states.includes(value)) &&
        (recipients.length === 0 || recipients.includes(value)),
    ))
  }, [ formikForm.values.filters ])

  const onNewQuoteClick = () => {
    navigate('nouveau')
  }

  const setOrder = (order: Order) => formikForm.setFieldValue('sort.order', order)
  const setOrderBy = (orderBy: DataName) => formikForm.setFieldValue('sort.orderBy', orderBy)

  return (
    <div>
      <LargeTitle>
        Devis
      </LargeTitle>
      <Form form={formikForm}>
        <FilterDesktopContainer>
          <CaseQuoteFilters
            typeList={[]}
            isFetchingTypeList={false}
            roleList={[]}
            isFetchingRoleList={false}
            recipientList={[]}
            isFetchingRecipientList={false}
          />
          <Button
            variant="contained"
            onClick={onNewQuoteClick}
          >
            Nouveau devis
          </Button>
        </FilterDesktopContainer>
        <FilterMobileContainer>
          <BoldSeparator />
          <FormTitleWithArrow onClick={() => setOpenedFilterMenu(!openedFilterMenu)}>
            Filtres
            <DropDownArrow open={openedFilterMenu} />
          </FormTitleWithArrow>
          <Collapse
            in={openedFilterMenu}
            timeout="auto"
            unmountOnExit
          >
            <CaseQuoteFilters
              typeList={[]}
              isFetchingTypeList={false}
              roleList={[]}
              isFetchingRoleList={false}
              recipientList={[]}
              isFetchingRecipientList={false}
            />
            <BoldSeparatorMargin />
          </Collapse>
          <Button
            variant="contained"
            fullWidth
            onClick={onNewQuoteClick}
          >
            Nouveau devis
          </Button>
        </FilterMobileContainer>
        <CaseQuoteTable
          filteredList={filteredList}
          isFetchingList={false}
          setFilteredList={() => null}
          setOrder={setOrder}
          setOrderBy={setOrderBy}
        />
      </Form>
      <Footer />
    </div>
  )
}

export default CaseQuotePage
