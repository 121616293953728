/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Component imports -------------------------------------------------------- */
import {
  Checkbox,
  FormControlLabel,
} from '@mui/material'

/* Styled components -------------------------------------------------------- */
const CheckboxButtonContainer = styled.div`
  align-self: end;
  height: fit-content;
  min-height: 50px;
`

interface CheckableFormControlLabelProps {
  checked: boolean;
  disabled: boolean;
}

const CheckableFormControlLabel = styled(FormControlLabel)<CheckableFormControlLabelProps>`
  border: 1px solid ${(props) => props.theme.colors.inputgrey};
  border-radius: 4px;
  padding-right: 5px;
  width: 100%;
  height: 100%;
  margin: 0;
  background-color: ${(props) => props.disabled ? props.theme.palette.info.dark : props.theme.colors.main};

  span.MuiTypography-root {
    width: 100%;
  }
`

const CustomCheckbox = styled(Checkbox)<CheckableFormControlLabelProps>`
  svg {
    fill: ${(props) => props.checked ? undefined : props.disabled ? props.theme.palette.info.dark : props.theme.palette.primary.main};
  }
`

/* Component declaration ---------------------------------------------------- */
interface CheckboxButtonProps {
  checked: boolean;
  onChange: (event: React.SyntheticEvent, checked: boolean) => void;
  label: React.ReactNode;
  className?: string;
  disabled?: boolean;
}

const CheckboxButton: React.FC<CheckboxButtonProps> = ({
  checked,
  onChange,
  label,
  className,
  disabled = false,
}) => {
  return (
    <CheckboxButtonContainer className={className}>
      <CheckableFormControlLabel
        checked={checked}
        onChange={onChange}
        label={label}
        disabled={disabled}
        control={
          <CustomCheckbox
            checked={checked}
            disabled={disabled}
          />
        }
      />
    </CheckboxButtonContainer>
  )
}

export default CheckboxButton
