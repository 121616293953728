/* Framework imports -------------------------------------------------------- */
import React from 'react'
import styled from '@emotion/styled'

/* Component imports -------------------------------------------------------- */
import FormBoldTitle from 'components/FormBoldTitle/FormBoldTitle'
import AutocompleteField from 'components/FieldWithInputAdornment/AutocompleteField'

/* Styled components -------------------------------------------------------- */
const SelectFilterContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, calc(25% - 10px));
  justify-content: space-between;
  align-items: flex-end;
  margin-top: -20px;

  @media ${(props) => props.theme.media.mobile.main} {
    display: initial;
  }
`

/* Component declaration ---------------------------------------------------- */
interface CaseInvoiceFiltersProps {
  typeList: string[];
  isFetchingTypeList: boolean;
  roleList: string[];
  isFetchingRoleList: boolean;
  recipientList: string[];
  isFetchingRecipientList: boolean;
}

const CaseInvoiceFilters: React.FC<CaseInvoiceFiltersProps> = ({
  typeList,
  roleList,
  recipientList,
  isFetchingRecipientList,
  isFetchingRoleList,
  isFetchingTypeList,
}) => {

  return (
    <SelectFilterContainer>
      <div>
        <FormBoldTitle>
          Types
        </FormBoldTitle>
        <AutocompleteField
          name="filters.types"
          options={typeList.map((value) => value)}
          getOptionLabel={(option) => typeList.find((value) => value === option) || ''}
          disabled={isFetchingTypeList}
          size="small"
          multiple
        />
      </div>
      <div>
        <FormBoldTitle>
          Rôle / État
        </FormBoldTitle>
        <AutocompleteField
          name="filters.states"
          options={roleList.map((value) => value)}
          getOptionLabel={(option) => roleList.find((value) => value === option) || ''}
          disabled={isFetchingRoleList}
          size="small"
          multiple
        />
      </div>
      <div>
        <FormBoldTitle>
          Destinataire
        </FormBoldTitle>
        <AutocompleteField
          name="filters.recipients"
          options={recipientList.map((value) => value)}
          getOptionLabel={(option) => recipientList.find((value) => value === option) || ''}
          disabled={isFetchingRecipientList}
          size="small"
          multiple
        />
      </div>
    </SelectFilterContainer>
  )
}

export default CaseInvoiceFilters
