/* Framework imports -------------------------------------------------------- */
import React, { useState } from 'react'
import styled from '@emotion/styled'
import * as Yup from 'yup'

/* Module imports ----------------------------------------------------------- */
import {
  useLocation,
  useNavigate,
} from 'react-router-dom'
import { useLazyGetCaseListQuery } from 'store/api'
import {
  useAppDispatch,
  useIsReadOnly,
} from 'store/hooks'
import { setRouterLastPath } from 'store/slices/routerHistorySlice'
import DateUtils from 'helpers/DateUtils'

/* Component imports -------------------------------------------------------- */
import {
  Form,
  useForm,
} from 'components/FormikLogic/FormikLogic'
import {
  Button,
  CircularProgress,
  Menu,
} from '@mui/material'
import SearchIcon from '@mui/icons-material/Search'
import { Field } from 'formik'
import { TextField } from 'formik-mui'

/* Type imports ------------------------------------------------------------- */
import type {
  FormikContextType,
  FormikHelpers,
} from 'formik'
import type { Shape } from 'components/FormikLogic/FormikLogic'

/* Type declarations -------------------------------------------------------- */
interface RechercheRapideDossierListParams {
  RechercheLibre: string;
}

const researchCaseSchema = Yup.object<Shape<RechercheRapideDossierListParams>>({
  RechercheLibre: Yup.string().required(''),
}).required()

type ResearchForm = FormikContextType<RechercheRapideDossierListParams>

/* Styled components -------------------------------------------------------- */
const SearchFieldContainer = styled.div`
  width: 100%;
  padding: 0px 10px 10px;
`

const IconButton = styled(Button)`
  min-width: auto;
`

const SearchField = styled(Field)`
  margin-right: 5px;

  .MuiInputBase-root {
    padding-left: 0;
  }
`

const MenuOverflow = styled(Menu)`
  .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
    overflow-x: auto;
  }
`

const Table = styled.table`
  padding: 0px 10px;
  border-spacing: 0px;

  thead {
    td {
      font-weight: bold;
    }
  }

  td {
    border: 1px solid lightgray;
    padding: 5px 10px;
  }
`

const TableRow = styled.tr`
  font-size: 14px;
  height: 30px;

  &:hover {
    background-color: lightgray;
    cursor: pointer;
  }

  td:first-of-type {
    font-weight: bold;
    color: ${(props) => props.theme.palette.primary.main};
  }
`

/* Component declaration ---------------------------------------------------- */
interface MainLayoutSearchFieldProps {
  closeDrawer: () => void;
}

const MainLayoutSearchField: React.FC<MainLayoutSearchFieldProps> = ({ closeDrawer }) => {
  const isReadOnly = useIsReadOnly()
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const location = useLocation()
  const [ anchorMenu, setAnchorMenu ] = useState<null | HTMLElement>(null)

  const [
    getCaseList,
    { currentData: caseList },
  ] = useLazyGetCaseListQuery()

  const openMenu = (): void => {
    const field = document.getElementById('search-field')
    setAnchorMenu(field)
  }

  const onSubmit = (values: RechercheRapideDossierListParams, { setSubmitting }: FormikHelpers<RechercheRapideDossierListParams>): void => {
    getCaseList(values, true)
      .then(openMenu)
      .catch(console.error)
      .finally(() => setSubmitting(false))
  }

  const formikForm: ResearchForm = useForm<RechercheRapideDossierListParams>(
    {
      initialValues: {
        RechercheLibre: '',
      },
      onSubmit: onSubmit,
      validationSchema: researchCaseSchema,
    },
  )

  const handleMenuClose = () => {
    setAnchorMenu(null)
  }

  const handleNavigate = (caseId: string) => {
    dispatch(setRouterLastPath(location.pathname))
    navigate(`/dossiers/${caseId}`)
    setAnchorMenu(null)
    closeDrawer()
  }

  return (
    <SearchFieldContainer>
      <Form form={formikForm}>
        <SearchField
          id="search-field"
          component={TextField}
          name="RechercheLibre"
          size="small"
          placeholder="Rechercher..."
          disabled={isReadOnly}
          InputProps={
            {
              startAdornment: (
                <IconButton
                  type="submit"
                  disabled={formikForm.isSubmitting}
                >
                  {
                    formikForm.isSubmitting ?
                      <CircularProgress size={26} /> :
                      <SearchIcon />
                  }
                </IconButton>
              ),
            }
          }
        />
        <MenuOverflow
          anchorEl={anchorMenu}
          open={Boolean(anchorMenu)}
          onClose={handleMenuClose}
        >
          <Table>
            <thead>
              <tr>
                <td>
                  Ref compagnie
                </td>
                <td>
                  Assuré
                </td>
                <td>
                  Ref sinistre
                </td>
                <td>
                  Nature du sinistre
                </td>
                <td>
                  Date
                </td>
              </tr>
            </thead>
            <tbody>
              {
                caseList?.resultats?.map((cases, index) => (
                  <TableRow
                    key={`${cases.id}-${index}`}
                    onClick={() => handleNavigate(cases.id)}
                  >
                    <td>
                      {cases.id}
                    </td>
                    <td>
                      {cases.assure}
                    </td>
                    <td>
                      {cases.referenceSinistre}
                    </td>
                    <td>
                      {cases.natureSinistre.libelle}
                    </td>
                    <td>
                      {DateUtils.APIStrToLocalDateString(cases.dateCreation)}
                    </td>
                  </TableRow>
                ))
              }
            </tbody>
          </Table>
        </MenuOverflow>
      </Form>
    </SearchFieldContainer>
  )
}

export default MainLayoutSearchField
